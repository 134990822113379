import { WEBSITE_URL } from "./website";

import axios from "axios"



export const apiClient = axios.create({
    baseURL: WEBSITE_URL,
    headers: {
        'Content-type': 'application/json',
        "Accept": "application/json",
        'ngrok-skip-browser-warning': '1231'
    },
});



export const setupInterceptors = () => {


    apiClient.interceptors.request.use(
        (config) => {
            // const state = store.getState();
            // const token = state.auth?.user?.token;
            // if (token && !config.skipAuth) {
            //     config.headers.Authorization = `Bearer ${token}`;
            // }
            if (config.isMultipart) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            if (!/^https?:\/\//i.test(config.url)) {
                config.url = `${config.baseURL}${config.url}`;
            }

           
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );


    apiClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // if (error.response && error.response.status === 401) {
            //     store.dispatch(clearUser());
            // }
            return Promise.reject(error);
        }
    );
};




//CUSTOMER API ROUTES

export const LOGIN_STORE_USER_URL = WEBSITE_URL + "/api/store-owner/login";
export const VIEW_ALL_CITIES_URL = WEBSITE_URL + "/api/get-cities";
export const VIEW_ALL_STORE_CATEGORIES_URL = WEBSITE_URL + "/api/get-store-categories";





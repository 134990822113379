import { Link } from "react-router-dom";
import Truncate from "react-truncate";
import ProgressiveImage from "react-progressive-image";
import { Rating } from "react-simple-star-rating";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../../api";


const TopCard = ({ store }) => {
    return (
        <>
            <Link to={'/single-store/' + store.id} key={store.id} className="" style={{ width: "20vw" }}>
                <div className={`d-flex flex-column ${!store?.is_active && 'store-not-active'}`} style={{ width: "22vw" }}>
                    <div className="position-relative text-center mt-2" >
                        <ProgressiveImage delay={20}
                            src={IMAGE_BASE_URL + store?.image}
                            placeholder={PLACE_HOLDER_IMAGE}   >
                            {(src, loading) => (
                                <img src={src}
                                    style={{ objectFit: "cover", backgroundRepeat: "none", height: "22vw", width: "22vw", borderRadius: "10px", }}
                                    className="" alt="grosav" />
                            )}
                        </ProgressiveImage>
                        {(store?.coupons?.length > 0 || store?.offer_promotion_text) &&
                            <div className="popular-tag-content px-1 py-1">
                                <div className="popular-tag">
                                    <p><b>{store?.coupons?.length > 0 &&
                                        store?.coupons[0]?.description.length > 0 ? (
                                        <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                            <Truncate lines={1}>
                                                {store?.coupons[0]?.description.toUpperCase()}
                                            </Truncate>
                                        </div>
                                    ) : (
                                        <div className='w-100' style={{ whiteSpace: 'nowrap', fontSize: "10px" }}>
                                            <Truncate lines={1}>
                                                {store?.offer_promotion_text}
                                            </Truncate>
                                        </div>
                                    )}</b></p>
                                </div>
                            </div>
                        }
                        {store.is_buy_text ? (
                            <div className="ribbon-buy-small">
                                <span>{store.is_buy_text}</span>
                            </div>
                        ) : null}
                    </div>
                    <Truncate lines={1} className="top-res-name fw-700">  {store?.name}   </Truncate>
                    {store?.rating > 0 ?
                        <div>
                            <Rating
                                size={"1em"}
                                className="mobile-rate-star"
                                initialValue={store?.rating}
                                readonly="true"
                            />
                        </div>
                        :
                        <Rating
                            size={"1em"}
                            className="mobile-rate-star"
                            initialValue={0}
                            readonly="true"
                        />
                    }
                    {store?.is_active ? (
                        <span className="top-res-deli-time text-muted fs-15">
                            <Truncate lines={1}>
                                {store?.preparation_time ? store.preparation_time + ' Min - ' : ""}  {store?.delivery_radius ? store.delivery_radius + ' Km ' : ""}
                            </Truncate>
                        </span>
                    ) : (
                        null
                    )}
                </div>
                {!store?.is_active ? (
                    <div className="text-danger">
                        Store Closed
                    </div>
                ) : (
                    null
                )}
            </Link>
        </>
    )
}

export default TopCard

import { PLACE_ORDER, EMPTY_CHECKOUT, GET_ORDER, GET_PAYMENT_GETAWAYS } from "./actionTypes";

const initialState = {
    checkout: [],
    orderStatus: [],
    payment_methods: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case EMPTY_CHECKOUT:
            return { ...state, checkout: action.payload };
        case GET_ORDER:
            return { ...state, orderStatus: action.payload };
        // case GET_PAYMENT_GETAWAYS:
        //     return { ...state, orderStatus: action.payload };
        case GET_PAYMENT_GETAWAYS:
            return {
                ...state,
                payment_methods: action.payload,  // Update payment_methods with the response
            };
        default:
            return state;
    }
}

import React, { useState, useEffect, useCallback } from 'react';
import { GiHamburger } from 'react-icons/gi';
import NearRestaurantCard from './components/NearRestaurantCard.js';
import { BiLoader } from 'react-icons/bi';
import { useLazyGetNearStoresQuery } from '../../../../redux/mobile/home/homeApi.js';
import debounce from "lodash.debounce";

const RestaurantNearYou = () => {
    const [page, setPage] = useState(1);
    const [getNearStores, { data: nearStore, isLoading ,isFetching}] = useLazyGetNearStoresQuery();
    const loading = isLoading || isFetching;
    const latitude = JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude;
    const longitude = JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude;

    useEffect(() => {
        const fetchStores = async () => {
            try {
                await getNearStores({
                    latitude,
                    longitude,
                    page
                }).unwrap();
            } catch (err) {
                console.error("Failed to fetch stores:", err);
            }
        };
        const isEnd = nearStore?.pagination?.total && page > Math.ceil(nearStore?.pagination?.total / 5);
        if (!isEnd)
            fetchStores();
    }, [page,]);




    const handleHorizontalScroll = useCallback(debounce((event) => {
        const { scrollWidth, scrollLeft, clientWidth } = event.target;
        if (scrollWidth - scrollLeft - clientWidth < 1 && !loading) {
            setPage((prevPage) => prevPage + 1);
        }
    }, 200), [loading]);

    return (
        <div className='px-3 mt-3'>
            {nearStore?.data?.length > 0 && (
                <React.Fragment>
                    <GiHamburger className='mb-2' color='FF9A62' size={20} />
                    <span className='top-res-header'>Restaurants Near You</span>
                </React.Fragment>
            )}
             {nearStore?.data?.length > 0 && (
            <div className='d-flex flex-row align-items-center'>
                <div
                    id="restaurantNearYou"
                    className='mt-3 hidden-scroll-bar gap-3'
                    style={{
                        display: "flex",
                        width: "100vw",
                        flexDirection: "row",
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                    }}
                    onScroll={handleHorizontalScroll}
                >
                    {nearStore?.data?.map((store, index) => (
                        <NearRestaurantCard key={index} store={store} />
                    ))}
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                height: "50px",
                                width: "50px",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "50px",
                                marginLeft: "10px",
                                marginRight: "20px",
                            }}
                        >
                            <BiLoader
                                className="loader"
                                style={{
                                    height: "20px",
                                    width: "20px",
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            )}
        </div>
    );
};

export default RestaurantNearYou;

import ProgressiveImage from 'react-progressive-image';
import { Link } from "react-router-dom";
import Truncate from 'react-truncate';
import { Rating } from 'react-simple-star-rating';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../../api';


const NearRestaurantCard = ({ store }) => {
    return (
        <>
            <Link to={'/single-store/' + store.id} key={store.id}>
                <div className={`mt-2 position-relative ${!store?.is_active && 'store-not-active'}`}>
                    <ProgressiveImage
                        delay={20}
                        src={IMAGE_BASE_URL + store.image}
                        placeholder={
                            PLACE_HOLDER_IMAGE
                        }
                    >
                        {(src, loading) => (
                            <img
                                src={src}
                                style={{
                                    objectFit: "cover",
                                    backgroundRepeat: 'none',
                                    height: '20vw',
                                    width: '20vw',
                                    borderRadius: '10px'
                                }}
                                className=""
                                alt="grosav"
                            />
                        )}
                    </ProgressiveImage>
                </div>
                <div className='top-res-name'>
                    <Truncate lines={1}>
                        <span>
                            {store.name}
                        </span>
                    </Truncate>
                    {store?.rating > 0 ?
                        <div>
                            <Rating
                                size={"1em"}
                                className="mobile-rate-star"
                                initialValue={store?.rating}
                                readonly="true"
                            />
                        </div>
                        :
                        <Rating
                            size={"1em"}
                            className="mobile-rate-star"
                            initialValue={0}
                            readonly="true"
                        />
                    }
                    {store?.is_active ? (
                        <div className='d-inline-block'>
                            <div>
                                <span className='top-res-deli-time text-muted '>
                                    {store.approx_time_delivery > 0 ?
                                        <>
                                            {store.approx_time_delivery}Mins
                                        </>
                                        :
                                        <>
                                            0Min
                                        </>
                                    }
                                    {store.delivery_radius > 0 ?
                                        <>
                                            - {store.delivery_radius}km
                                        </>
                                        :
                                        <>
                                            - 0km
                                        </>
                                    }
                                </span>
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                </div>
                {!store?.is_active ? (
                    <div className="text-danger">
                        Store Closed
                    </div>
                ) : (
                    null
                )}
            </Link>
        </>
    )
}

export default NearRestaurantCard

import React, { Component } from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { TbDiscount2 } from 'react-icons/tb'
import ProgressiveImage from 'react-progressive-image'
import { connect } from 'react-redux'
import moment from 'moment'
import Truncate from 'react-truncate'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../api'
import Countdown, { zeroPad } from "react-countdown";
import MobileBackButtonWithCenterTitle from '../../Elements/MobileBackButtonWithCenterTitle'
import { AiOutlineMinus, AiOutlinePlus, AiOutlineStop } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { addProduct, removeProduct } from '../../../redux/mobile/cart/action'
import FloatCart from '../Cart/FloatCart'
import Customization from '../Customization'
import ComboCustomization from '../ComboCustomization'

export class SingleDealsToday extends Component {

    state = {
        data: false,
        isRunning: false,
    }
    getDay = (start) => {
        let dayNow = moment().format("d-m-Y");
        let dayDate = moment(start).format("d-m-Y");
        let day = moment(dayDate, "d-m-Y").diff(moment(dayNow, "d-m-Y"), 'days');
        return day;

    }
    getTime = (start) => {
        let timeNow = moment().format("HH:mm:ss a");
        let startDate = moment(start).format("HH:mm:ss a");
        let hours = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("hh");
        let minutes = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("mm");
        let seconds = moment.utc(moment(startDate, "HH:mm:ss").diff(moment(timeNow, "HH:mm:ss"))).format("ss");
        let result = hours * 3600000 + minutes * 60000 + seconds * 1000;
        return result;

    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    render() {
        const timeRenderer = ({ days, hours, minutes, seconds }) => {
            return (
                <div className='d-flex align-items-center justify-content-center' style={{ color: '#e30000', fontWeight: '700' }}>
                    <span>
                        {zeroPad(hours)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(minutes)}
                    </span>
                    <span>:</span>
                    <span>
                        {zeroPad(seconds)}
                    </span>
                </div>
            );
        }
        const { deals_today, user, cartProducts, addProduct, removeProduct } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                <div className="mobile-top-offer-bg pt-4" style={{ paddingBottom: '30vw' }}>
                    <div>
                        <MobileBackButtonWithCenterTitle title="Deals Today" history={this.props.history} />
                    </div>
                    {deals_today && deals_today.items && deals_today.items.length > 0 ? (
                        deals_today?.items.map((item, index) => (
                            <div className='px-4 mt-2'>
                                <div className='main-menu hidden-scroll-bar gap-2 ' style={{ gridTemplateRows: `repeat(${item?.length} ,1fr)` }}>
                                    <div className='d-flex justify-content-between mt-2 px-2 py-2' style={{ borderRadius: "10px", backgroundColor: ' #ececec' }}>
                                        <div className='d-flex flex-column justify-content-between align-items-center position-relative'>
                                            <ProgressiveImage
                                                delay={20} src={IMAGE_BASE_URL + item.image}
                                                placeholder={PLACE_HOLDER_IMAGE}  >
                                                {(src, loading) => (
                                                    <img src={src}
                                                        style={{
                                                            width: "25vw", height: "25vw", borderRadius: "15%", objectFit: "cover", backgroundRepeat: "round",
                                                            backgroundPosition: 'center', backgroundSize: 'contain'
                                                        }}
                                                        className="" alt="grosav" />
                                                )}
                                            </ProgressiveImage>
                                            <div className='d-flex align-items-center justify-content-between text-center' style={{ color: '#e30000' }}>
                                                <div className='me-2' style={{ fontSize: '.9em', whiteSpace: 'nowrap', fontWeight: '700', }}>
                                                    Ends In
                                                </div>
                                                {this.getDay(item.dealofdays[0].end_time) > 0 ? this.getDay(item.dealofdays[0].end_time) + " days" : (
                                                    <span className='countdown-deal-today' style={{ fontSize: '.8em', color: '#e30000', fontWeight: '700' }}>
                                                        <Countdown
                                                            date={Date.now() + this.getTime(item.dealofdays[0].end_time)}
                                                            renderer={timeRenderer}
                                                        // onComplete={() => {
                                                        //     checkOrderStatus();
                                                        // }}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                            <div className="popular-tag-content-top px-2">
                                                <div className="popular-tag" >
                                                    <p>{item.dealofdays[0].offer}% off</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-grow-1 top-res-name d-flex flex-column justify-content-between ms-3 w-100'>
                                            <Truncate lines={1}>
                                                {item.name}
                                            </Truncate>
                                            <Truncate lines={1} style={{ fontSize: '.9em' }}>
                                                {item.store.name}
                                            </Truncate>

                                            <div style={{ color: "#B3B2B6", fontSize: '0.9em' }}>
                                                <Truncate lines={2}>
                                                    {item.description}
                                                </Truncate>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-bottom mt-1'>
                                                {item?.selling_price > 0 ?
                                                    <div>
                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                            Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                        </span>
                                                        <s className='ms-2' style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                            Rs. {item?.selling_price}
                                                        </s>
                                                    </div>
                                                    :
                                                    <div className='d-flex justify-content-between text-center'>
                                                        <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9A62' }}>
                                                            {item.dealofdays[0].offer}% OFF
                                                        </span>
                                                    </div>}
                                                {user && user.success > 0 ? (
                                                    <React.Fragment>

                                                        {item?.stock > 0 ? (

                                                            <>
                                                                {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                    <>
                                                                        <ComboCustomization
                                                                            floatCart={(e) => this.floatCart(e)}
                                                                            product={item}
                                                                            addProduct={addProduct}
                                                                            dealToday={true}
                                                                            forceUpdate={this.forceStateUpdate} />
                                                                    </>
                                                                ) : item?.addoncategories?.length > 0 ? (
                                                                    <>
                                                                        {/* {////// console.log(item, 'iteemmmmmm')} */}
                                                                        <Customization
                                                                            floatCart={(e) => this.floatCart(e)}
                                                                            product={item}
                                                                            addProduct={addProduct}
                                                                            forceUpdate={this.forceStateUpdate}
                                                                            dealToday={true}
                                                                        />
                                                                    </>
                                                                ) :
                                                                    cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                        <>
                                                                            {cartProducts.filter((cp) => cp.id === item.id).map((product) =>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="deal-top-food-btn" onClick={() => {
                                                                                        item.quantity = 1;
                                                                                        removeProduct(item);
                                                                                        this.forceStateUpdate();
                                                                                    }}>
                                                                                        <AiOutlineMinus
                                                                                            style={{
                                                                                                color: "#fff",
                                                                                            }}
                                                                                            size={14}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="top-food-count ms-2 me-2">
                                                                                        {product.quantity}
                                                                                    </div>
                                                                                    {product.stock > product.quantity ? (
                                                                                        <div className="deal-top-food-btn" onClick={() => {
                                                                                            item.quantity = 1;
                                                                                            addProduct(item);
                                                                                            this.forceStateUpdate();
                                                                                        }}>
                                                                                            <AiOutlinePlus
                                                                                                style={{
                                                                                                    color: "#fff",
                                                                                                }}
                                                                                                size={14}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="deal-top-food-btn">
                                                                                            <AiOutlineStop
                                                                                                style={{
                                                                                                    color: "#fff",
                                                                                                }}
                                                                                                size={17}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div className="d-flex align-items-center" style={{ color: "#000", }}>
                                                                            <div className="top-food-btn" onClick={() => {
                                                                                item.quantity = 1;
                                                                                addProduct(item);
                                                                                this.forceStateUpdate();
                                                                            }} >
                                                                                Add <AiOutlinePlus

                                                                                    size={14}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <div className="d-flex align-items-center">
                                                                <div className="deal-top-food-btn" style={{ background: '#fff' }}>
                                                                    <AiOutlineStop
                                                                        style={{
                                                                            color: "#FF9A62",
                                                                        }}
                                                                        size={17}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <Link to="/signUp">
                                                        <div className="d-flex align-items-center" style={{ color: "#fff" }}>
                                                            <div className="top-food-btn">
                                                                Add <AiOutlinePlus color={"#fff"} size={14} />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))) : ("sss")}
                </div>
                <FloatCart isRunning={this.state.isRunning} noFooter={true} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    cartProducts: state.cart.products,
    deals_today: state.home.deals_today
})
export default connect(mapStateToProps, {
    addProduct,
    removeProduct
})(SingleDealsToday)

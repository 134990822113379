import React, { Component } from "react";
import { connect } from "react-redux";
import BackButtonWithNotification from "../../Elements/backButtonWithNotification";
import { BiSearch } from "react-icons/bi";
import filter from "../../../assets/images/Filter.png";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from 'react-content-loader'
import Lottie from 'react-lottie';
import animationData from "../../../assets/lottie/search.json"
import { Link } from 'react-router-dom';
import {
    getMobileSearchItems,
    getSearchCategories,
} from '../../../redux/mobile/item/action'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../api/index';
import { BottomSheet } from "react-spring-bottom-sheet";
import { IoMdClose } from 'react-icons/io';
import Truncate from "react-truncate";



export class ExploreMenu extends Component {
    state = {
        loading: false,
        items: [],
        stores: [],
        filtermenu: false,
        itemCategories: [],
        filters: ['ALL'],
        filtercategories: ['ALL'],
        searchinput: ''
    }
    componentDidMount() {
        this.props.getSearchCategories(JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
            .then((response) => {
                this.setState({ itemCategories: response.data.itemCategories });

            })
    }
    searchItems = (input) => {
        this.setState({ searchinput: input })
        if (input?.length >= 4) {

            this.props.getMobileSearchItems(input, this.state.filtercategories, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                .then((response) => {
                    this.setState({
                        items: response.payload.items,
                        stores: response.payload.stores
                    })
                })
        }
    }
    openMenu = () => {
        this.setState({
            filtermenu: true,
        });
    };
    closeMenu = () => {
        this.setState({ filtermenu: false });
    };
    searchfilter = (category) => {
        if (category === 'ALL') {
            this.setState({
                filters: ['ALL'],
                filtercategories: ['ALL'],
            });
            this.props.getMobileSearchItems(this.state.searchinput, ['ALL'], JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                .then((response) => {
                    this.setState({
                        items: response.payload.items,
                        stores: response.payload.stores
                    })
                })
        } else {
            if (this.state.filters.includes('ALL')) {
                this.setState({
                    filters: [category],
                    filtercategories: [category.id],
                }, () => {
                    this.props.getMobileSearchItems(this.state.searchinput, this.state.filtercategories, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                        .then((response) => {
                            this.setState({
                                items: response.payload.items,
                                stores: response.payload.stores
                            })
                        })
                });
            } else {
                this.setState({
                    filters: [...this.state.filters, category],
                    filtercategories: [...this.state.filtercategories, category.id]
                }, () => {
                    this.props.getMobileSearchItems(this.state.searchinput, this.state.filtercategories, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                        .then((response) => {
                            this.setState({
                                items: response.payload.items,
                                stores: response.payload.stores
                            })
                        })
                });
            }
        }
        this.closeMenu();
    }
    removefilter = (data) => {
        const newfilters = this.state.filters.filter((category) => !(category.id === data.id));
        const newcategories = this.state.filtercategories.filter((category) => category !== data.id);
        if (newfilters.length === 0) {
            this.setState({
                filters: ['ALL'],
                filtercategories: ['ALL'],
            }, () => {
                this.props.getMobileSearchItems(this.state.searchinput, this.state.filtercategories, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                    .then((response) => {
                        this.setState({
                            items: response.payload.items,
                            stores: response.payload.stores
                        })
                    })
            });
        } else {
            this.setState({
                filters: newfilters,
                filtercategories: newcategories,
            }, () => {
                this.props.getMobileSearchItems(this.state.searchinput, this.state.filtercategories, JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude)
                    .then((response) => {
                        this.setState({
                            items: response.payload.items,
                            stores: response.payload.stores
                        })
                    })
            });
        }
    }
    render() {
        const defaultOptions = {
            // loop: true,
            // autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="20" y="36" rx="10" ry="20" width="32" height="28" />
                                <rect x="224" y="36" rx="10" ry="20" width="32" height="28" />
                                <rect x="20" y="80" rx="10" ry="20" width="176" height="30" />
                                <rect x="224" y="80" rx="10" ry="20" width="32" height="28" />
                                {/* <rect x="20" y="142" rx="10" ry="20" width="200" height="31" /> */}
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="d-flex flex-column pb-3">
                                <div className="mobile-ntfcn-head py-4 d-flex position-relative">
                                    <div className="pt-3 px-1" style={this.state.filtermenu ? { display: 'none' } : { display: 'block' }}>
                                        <BackButtonWithNotification history={this.props.history} />
                                    </div>
                                    <div className="mt-5 ms-4 ">
                                        <div className="d-flex justify-content-between">
                                            {/* <Link to="/notifications">
                                                <div className="notification-explore-menu d-flex position-absolute p-1"
                                                    style={{ top: '33px', right: '-4px' }}>
                                                    <div className="m-1 ps-1">
                                                       <FiBell
                                                            style={{
                                                                color: "#FEA24B",
                                                                fontSize: "1.4rem",
                                                                // padding: "2%",
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="m-1" >
                                                     <img src={image} alt="notification" />
                                                    </div>
                                                </div>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>

                                {/* search start */}
                                <div className="px-3 d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center explore-menu-searchBar  py-2 px-2">
                                        <div className="">
                                            <BiSearch
                                                set="curved"
                                                style={{
                                                    fontSize: "2em",
                                                    color: "#DA6317",
                                                }}
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            className="mobile-inventory-search w-full "
                                            style={{ paddingLeft: "1em" }}
                                            // value={this.state.searchinput}
                                            autoFocus
                                            placeholder="What do you want to order ?"
                                            onChange={(event) => { this.searchItems(event.target.value) }}
                                        />
                                    </div>
                                    {/* <div className="filter-image-exploremenu"
                                        onClick={() => this.openMenu()}>
                                        <img src={filter} alt="filter" />
                                    </div> */}
                                </div>

                                {/* selected filters start*/}
                                {!this.state?.filters?.includes('ALL') && this.state?.filters?.length > 0 ? (
                                    <div className="px-3">
                                        <div className="main-menu hidden-scroll-bar gap-1 mt-3"
                                            style={{ gridTemplateColumns: 'repeat(3,1fr)' }}>
                                            {this.state?.filters?.map((filter) =>
                                                <div className="explore-menu-filter-tag px-2 py-2 d-flex align-items-center justify-content-between">
                                                    <span className='ps-1'>{filter.name}</span>
                                                    <div className="" onClick={() => this.removefilter(filter)}>
                                                        <IoMdClose size={'1.3em'} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {/*Lottie start */}
                                {this.state.items?.length === 0 && this.state.stores?.length === 0 ? (
                                    <>
                                    {this.state.searchinput?.length >= 4 &&
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <h6>No stores or items found</h6>
                                            </div>

                                        }
                                        <div className="mt-5 pt-5">
                                            <Lottie
                                                options={defaultOptions}
                                                width={300}
                                            />
                                        </div>

                                    </>

                                ) : (
                                    ""
                                )}

                                {/* store mapping start */}
                                {this.state?.stores?.length > 0 ? (
                                    <>
                                        <div className="description px-4 mt-3">
                                            Popular Stores ({this.state?.stores?.length})
                                        </div>
                                        <div  className="d-flex mt-3 px-2"
    style={{
        overflowX: 'scroll', // Enable horizontal scrolling
        whiteSpace: 'nowrap', // Prevent line breaks for child elements
        paddingBottom: '1em', // Optional for spacing
        gap: '0.2em', // Optional for spacing between items
    }}>
                                            {this.state?.stores?.map((store) =>
                                                <Link to={"/single-store/" + store.id}>
                                                    <div className="d-flex flex-column align-items-center justify-content-center restaraunt-image mt-3" style={{ marginRight: "1em",minWidth:"150px",minHeight:"200px" }}>
                                                        <div className="px-2">
                                                            <ProgressiveImage
                                                                delay={20}
                                                                src={IMAGE_BASE_URL + store.image}
                                                                placeholder={
                                                                    PLACE_HOLDER_IMAGE
                                                                }
                                                            >
                                                                {(src, loading) => (
                                                                    <img
                                                                        className=""
                                                                        style={{
                                                                            width: "21vw",
                                                                            objectFit: "cover",
                                                                            height: "19vw",
                                                                            borderRadius: "10px",
                                                                            backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                                            backgroundRepeat: "round",
                                                                            // marginLeft: "-0.6em",
                                                                            marginTop: "2em",
                                                                        }}
                                                                        src={src}
                                                                        alt="main-item"
                                                                    />
                                                                )}
                                                            </ProgressiveImage>
                                                        </div>
                                                        <div className='text-dark text-center mx-2'
                                                            style={{
                                                                fontSize: "16px",
                                                                fontWeight: "700",
                                                                paddingTop: "1em",
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Truncate lines={1}>
                                                                {store.name}
                                                            </Truncate>
                                                        </div>
                                                        <div className='text-secondary'
                                                            style={{
                                                                paddingBottom: "1em",
                                                                fontSize: "13px",
                                                                opacity: "0.8",
                                                            }}
                                                        >
                                                            {store.approx_time_delivery > 0 ?
                                                                <>
                                                                    {store.approx_time_delivery} Mins
                                                                </>
                                                                :
                                                                <>
                                                                    0 Min
                                                                </>
                                                            }
                                                        </div>
                                                        {store.is_active == 0 &&

                                                            <div className="text-danger">
                                                    Store Closed
                                                </div>
                                                        }
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                                {/* item mapping start */}
                                {this.state?.items?.length > 0 ? (
                                    <>
                                        <div className="description px-4 mt-3">
                                            Popular Items ({this.state?.items?.length})
                                        </div>
                                        {this.state?.items?.map((item) =>
                                            <Link to={'/single-product/' + item.id}>
                                                <div className="d-flex explore-menu-image px-3 my-1 mx-3">
                                                    <div className="">
                                                        <ProgressiveImage
                                                            delay={20}
                                                            src={IMAGE_BASE_URL + item.image}
                                                            placeholder={
                                                                PLACE_HOLDER_IMAGE
                                                            }
                                                        >
                                                            {(src, loading) => (
                                                                <img
                                                                    className=""
                                                                    style={{
                                                                        width: "15vw",
                                                                        objectFit: "cover",
                                                                        height: "16vw",
                                                                        borderRadius: "10px",
                                                                        backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                                        backgroundRepeat: "round",
                                                                        marginLeft: "-0.6em"
                                                                    }}
                                                                    src={src}
                                                                    alt="main-item"
                                                                />
                                                            )}
                                                        </ProgressiveImage>
                                                    </div>
                                                    <div className="mx-2 d-flex flex-column" style={{ color: "#09051C", fontSize: "15px", fontWeight: "700" }}>
                                                        <Truncate lines={2}> {item.name} </Truncate>
                                                        <div className="" style={{ color: "#9c9c9c", fontSize: "14px", opacity: ".8" }}>
                                                            {item?.store?.name}
                                                        </div>
                                                        <div className="d-flex">
                                                        <div>
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "600",
                                                                    color: "#09051C",
                                                                    fontSize:
                                                                        "16px",
                                                                }}
                                                            >
                                                                ₹{item.selling_price ?? 0}
                                                            </span>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}

                            </div>
                        </React.Fragment>
                    )}
                {/* category filter start */}
                <BottomSheet
                    open={this.state.filtermenu}
                    onDismiss={() => this.closeMenu()}
                    snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                >
                    {this.state?.itemCategories?.length > 0 ? (
                        <div style={{ paddingBottom: '24vw' }}>
                            <div className="px-2"
                                onClick={() => this.searchfilter('ALL')}>
                                <div className="px-3 d-flex flex-column align-items-center">
                                    <div className="explore-menu-filter-item w-100 ">
                                        ALL
                                    </div>
                                </div>
                            </div>
                            {this.state?.itemCategories?.map((category) =>
                                <>
                                    <div className="px-2"
                                        onClick={() => this.searchfilter(category)}>
                                        <div className="px-3 d-flex flex-column align-items-center ">
                                            <div className={
                                                this.state?.filters?.includes(category)
                                                    ? "explore-menu-filter-item w-100 text-black border-dark"
                                                    : "explore-menu-filter-item w-100"}>
                                                {category.name}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="px-2">
                            <div className="px-3 d-flex flex-column align-items-center ">
                                <div className="explore-menu-filter-item w-100 ">
                                    ALL
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="px-3 py-3 d-flex position-fixed w-100" style={{ bottom: '0', backgroundColor: '#fff' }}>
                        <button className="filter-button w-100 text-white ">
                            Filter
                        </button>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getMobileSearchItems, getSearchCategories })(ExploreMenu);

import React, { Component } from "react";
import { connect } from "react-redux";
import FoodCategory from "../Food/FoodCategory";
import { BiSearch } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { MdFoodBank } from "react-icons/md";
import { TbDiscount2 } from "react-icons/tb";
import TopRestaurant from "../Food/TopRestaurant";
import FoodBanner from "./FoodBanner";
import TopFood from "./TopFood";
import PopularBrands from "./PopularBrands";
import Footer from "../Footer";
import TopDeals from "./TopDeals";
import RestaurantNearYou from "./RestaurantNearYou";
import RecommendedForYou from "./RecommendedForYou";
import AllResturantForYou from "./AllResturantForYou";
import map from "../../../assets/images/map.png";
import { Link } from "react-router-dom";
import ContentLoader from 'react-content-loader'
import { getAllBannerForFood, getAllItemGroup, getAllPopularStores,  } from "../../../redux/mobile/home/action.js";
import { updateUserReduxInfo } from "../../../redux/mobile/user/action.js";
import Truncate from 'react-truncate';
import DealsToday from "./DealsToday";
import FoodFilter from "./FoodFilter";
import IosVersion from "../iosVersion/index.js";

export class Food extends Component {
    state = {
        loading: false,
        address: [],
        scrollPosition: 0

    }
    componentDidMount() {

        const { user } = this.props;
        this.setState({ loading: true });
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")) })
        this.props.getAllItemGroup(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude);
        this.props.getAllPopularStores(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
        this.props.getAllBannerForFood(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                if (res?.payload?.success) {
                    this.setState({ loading: false });
                }
            })
        if (user?.data?.auth_token) {
            this.props.updateUserReduxInfo(user?.data?.auth_token)
        }

    }
    render() {
        const { user, allStores, banners, item_group, popularStores } = this.props;
        const { address } = this.state;
        console.log(allStores, 'allStores');
        console.log(banners, 'banners');
        console.log(item_group, 'item_group');
        console.log(item_group?.itemGroup?.length, 'item_group?.data?.length');
        const prevStoreIdForScroll = localStorage.getItem('prevStoreIdForScroll');

        if (prevStoreIdForScroll && prevStoreIdForScroll.includes('single-store/')) {

            const savedScrollPosition = localStorage.getItem('scrollPosition');


            if (savedScrollPosition) {
                //// console.log('====================================');
                //// console.log(parseInt(savedScrollPosition, 10), 'jk');
                //// console.log('====================================');
                setTimeout(() => {
                    window.scrollTo({
                        top: parseInt(savedScrollPosition, 10),
                        behavior: "smooth",
                    });
                }, 100); // Adjust the delay if necessary

                setTimeout(() => {
                    // localStorage.removeItem('scrollPosition');
                    localStorage.removeItem('prevStoreIdForScroll');

                }, 2000);
            }
        } else {
            setTimeout(() => {
                localStorage.removeItem('scrollPosition');
                localStorage.removeItem('prevStoreIdForScroll');

            }, 2000);
        }
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="10" y="8" rx="10" ry="20" width="22" height="22" />
                                <rect x="40" y="13" rx="0" ry="0" width="50" height="12" />
                                <rect x="10" y="33" rx="0" ry="0" width="100" height="6" />
                                <rect x="185" y="15" rx="0" ry="0" width="60" height="18" />
                                <rect x="250" y="12" rx="8" ry="8" width="22" height="22" />
                                <rect x="12" y="50" rx="10" ry="10" width="254" height="47" />
                                <rect x="12" y="110" rx="10" ry="10" width="200" height="100" />
                                <rect x="220" y="110" rx="10" ry="10" width="100" height="100" />
                                <rect x="12" y="240" rx="8" ry="8" width="27" height="28" />
                                <rect x="50" y="240" rx="5" ry="5" width="115" height="28" />
                                <circle cx="45" cy="320" r="30" />
                                <circle cx="114" cy="320" r="30" />
                                <circle cx="183" cy="320" r="30" />
                                {/* <rect x="12" y="370" rx="12" ry="12" width="74" height="25" />
                                <rect x="95" y="370" rx="12" ry="12" width="74" height="25" />
                                <rect x="178" y="370" rx="12" ry="12" width="74" height="25" /> */}
                                <rect x="12" y="420" rx="8" ry="8" width="27" height="28" />
                                <rect x="50" y="420" rx="5" ry="5" width="115" height="28" />
                                <rect x="18" y="465" rx="10" ry="10" width="140" height="94" />
                                <rect x="185" y="465" rx="10" ry="10" width="140" height="94" />

                            </ContentLoader>
                        </React.Fragment>
                    ) : (<React.Fragment>
                        <div className=" " style={{ paddingBottom: '24vh' }}>
                            <div className='mobile-home-bg-img py-3 d-flex flex-column px-3 justify-content-between '>
                                <div className='d-flex flex-row justify-content-between w-100'>
                                    <div className='d-flex flex-row align-items-center'>
                                        <MdFoodBank style={{ fontSize: '2em' }} />
                                        <span className='mobile-span-home ps-2'>Food</span>
                                    </div>
                                    <div className='d-flex flex-row align-items-center'>
                                        {user && user.success ? (
                                            <Link to="/my-coupon" className='text-dark'>
                                                <div>
                                                    <TbDiscount2 style={{ fontSize: "30px" }} />
                                                    <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link to="/signup" className='text-dark'>
                                                <div>
                                                    <TbDiscount2 style={{ fontSize: "30px" }} />
                                                    <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                </div>
                                            </Link>
                                        )}
                                        {user && user.success && user?.data?.auth_token ? (
                                            <Link to="/my-profile" className='text-dark'>
                                                <div>
                                                    <CgProfile style={{ fontSize: "30px" }} />
                                                </div>
                                            </Link>
                                        ) : (
                                            <Link to="/signup" className='text-dark'>
                                                <div>
                                                    <CgProfile style={{ fontSize: "30px" }} />
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className='w-75 mobile-home-span-2 mt-2'>
                                    <Link to={'/set-location'} style={{ color: '#000' }}>
                                        <div >
                                            <Truncate lines={1}>
                                                {address?.house ? address?.house + "," : ''}{" " + address?.address}
                                            </Truncate>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className='px-3 sticky-search'>
                                <Link to="/explore-menu">
                                    <div className="d-flex flex-row align-items-center mobile-searchBar py-2 px-2">
                                        <input
                                            type="text"
                                            className="mobile-inventory-search w-100"
                                            placeholder="What do you want to order ?"
                                        />
                                        <span className="">
                                            <BiSearch set="curved" style={{ fontSize: "1.7em", color: "#FEA24B", }} />
                                        </span>
                                    </div>
                                </Link>
                            </div>
                            {(allStores?.data?.length > 0 || banners?.data?.length > 0 || item_group?.itemGroup?.length > 0) ? (
                                <div>
                                    <FoodBanner banners={banners} />
                                    <FoodCategory item_group={item_group} />
                                    <DealsToday />
                                    <TopRestaurant popularStores={popularStores} />
                                    <TopFood />
                                    <PopularBrands />
                                    <TopDeals />
                                    <RestaurantNearYou />
                                    <RecommendedForYou />
                                    {/* <FoodFilter /> */}
                                    <AllResturantForYou  />
                                </div>
                            ) : (
                                <div className='mt-5 d-flex flex-column align-items-center'>
                                    <div className='mt-5 pt-3 d-flex flex-column align-items-center'>
                                        <img src={map} alt="no items" style={{ objectFit: 'cover', width: '70%' }} />
                                    </div>
                                    <span className='mt-4 fw-bolder fs-6'>Sorry!</span>
                                    <span className='mt-1 fw-bolder fs-6'>We don't deliver to your location.</span>
                                </div>
                            )}
                        </div>

                        <Footer active_food={true} />
                    </React.Fragment>)
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    banners: state.home.banners,
    item_group: state.home.item_group,
    popularStores: state.home.popularStores,
    allStores: state.home.allStores,
});



export default connect(mapStateToProps, {
    getAllBannerForFood, getAllItemGroup, getAllPopularStores, updateUserReduxInfo
})(Food);

import React, { Component } from "react";
import { connect } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import { AiFillStar, AiFillHeart, AiOutlineHeart, AiOutlineMinus, AiOutlineFieldTime, AiOutlinePlus, AiOutlineStop } from "react-icons/ai";
import { ImSpoonKnife } from "react-icons/im";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import Truncate from 'react-truncate';
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import { getTopFood } from "../../../../redux/mobile/home/action.js";
import { toggleFavItem } from "../../../../redux/mobile/item/action.js";
import { addProduct, removeProduct } from '../../../../redux/mobile/cart/action'
import FloatCart from '../../Cart/FloatCart'
import Customization from "../../Customization";
import { TbDiscount2 } from "react-icons/tb";
import ComboCustomization from "../../ComboCustomization";

export class TopFood extends Component {
    state = {
        data: false,
        reload: true,
        float: false,
        isRunning: true,
    }
    // componentDidMount() {
    //     this.props.getTopFood(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
    //         if (response && response.payload && response.payload.success) {
    //             this.setState({ data: true });
    //         }
    //     });
    // }
    toggleFavItem = (item) => {
        const { topFoods } = this.props;
        const { user } = this.props;
        const singleItem = topFoods.data.find(data => data.id === item);
        const favUser = singleItem.favorites.find((fav) => fav.user_id === user.data.id)
        const favIndex = singleItem.favorites.indexOf(favUser);
        if (favUser) {
            singleItem.favorites.splice(favIndex, 1);
        } else {
            singleItem.favorites.push({ user_id: user.data.id });
        }
        this.props = { ...this.props, topFoods: topFoods }
        this.setState({ reload: !this.state.reload })
        this.props.toggleFavItem(this.props.user.data.auth_token, item)
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    floatCart = (on) => {
        this.setState({ float: on })
    }
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
        total = Math.round(total/count * 100) / 100;
        ;
        return total;
    }
    render() {
        const { data } = this.state;
        const { user, topFoods, addProduct, cartProducts, removeProduct } = this.props;
        return (
            <React.Fragment>
                {data ? topFoods.success && topFoods?.data?.length > 0 &&
                    <div>
                        <div className="px-3 d-flex align-items-center mt-3">
                            <GiHamburger size={20} style={{ color: "FF9A62" }} />
                            <span className="home-heading ms-2">Top Food In Town</span>
                        </div>
                        <div className="d-flex flex-row align-items-center ">
                            <div className="main-menu hidden-scroll-bar gap-3 p-3"
                                style={{ gridTemplateColumns: 'repeat(' + topFoods?.data?.length + ',1fr)' }} >
                                {topFoods?.data?.map((item) => (
                                    <div className="p-3 top-food-item-card ">
                                        <div className="d-flex flex-column position-relative b-r-40">
                                            {/* <Ink className="touch-ink " /> */}
                                            <Link to={'/single-product/' + item.id}>
                                                <ProgressiveImage delay={20} src={IMAGE_BASE_URL + item?.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}  >
                                                    {(src) => (
                                                        <img src={src}
                                                            style={{ width: "55vw", height: "35vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round", }}
                                                            className="" alt="grosav" />
                                                    )}
                                                </ProgressiveImage>
                                            </Link>
                                            {item?.market_price > item?.selling_price &&
                                                <div className="ribbon">
                                                    <span> <TbDiscount2 size={13} /> {Math.round(((item.market_price - item.selling_price) / item.market_price) * 100)} % off</span>
                                                </div>
                                            }
                                            {user && user.success > 0 ? (
                                                <div className="d-flex position-absolute"
                                                    style={{ color: '#FF9012', top: '8px', right: '11px' }}
                                                    onClick={() => this.toggleFavItem(item.id)}>
                                                    {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                        <AiFillHeart size={"1.6em"} />
                                                    ) : (
                                                        <AiOutlineHeart size={"1.6em"} />
                                                    )}
                                                </div>
                                            ) : (
                                                <Link to="/signUp">
                                                    <div className="d-flex position-absolute"
                                                        style={{ color: '#FF9012', top: '8px', right: '11px' }}>
                                                        <AiOutlineHeart size={"1.6em"} />
                                                    </div>
                                                </Link>
                                            )}
                                            <div className="d-flex flex-column mt-2 ">
                                                <Link to={'/single-product/' + item.id}>
                                                    <div className=" d-flex flex-row justify-content-between align-items-center home-heading">
                                                        <div className="flex-grow-1 w-100 fw-700">
                                                            <Truncate lines={1}>
                                                                {item?.name}
                                                            </Truncate>
                                                            {item?.ratings.length > 0 ?
                                                                <div className="ms-3 top-food-color float-end">
                                                                    {this.ratingCount(item?.ratings, item?.ratings.length)} <AiFillStar style={{ color: "FF9A62", }} size={15} />{" "}
                                                                </div>
                                                                :
                                                                <div className="ms-3 top-food-color float-end">
                                                                    {item?.rating} <AiFillStar style={{ color: "FF9A62", }} size={15} />{" "}
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link to={'/single-product/' + item.id}>
                                                    <div className="d-flex align-items-center">
                                                        <ImSpoonKnife
                                                            style={{
                                                                color: "FF9A62",
                                                            }}
                                                            size={10}
                                                        />
                                                        <span
                                                            className=" ms-2"
                                                            style={{
                                                                color: "#3B3B3B",
                                                            }}
                                                        >
                                                            {item?.store?.name}
                                                        </span>
                                                    </div>
                                                </Link>
                                                <Link to={'/single-product/' + item.id}>
                                                    <div className="d-flex align-items-center ">
                                                        <AiOutlineFieldTime
                                                            style={{
                                                                color: "FF9A62",
                                                                fontSize: "12px",
                                                            }}
                                                            size={14}
                                                        />
                                                        <span
                                                            className=" ms-2"
                                                            style={{
                                                                color: "#3B3B3B",
                                                                fontSize: "10px",
                                                            }}
                                                        >

                                                            {item?.store?.approx_time_delivery ? item?.store.approx_time_delivery + ' Min - ' : ""}  {item?.store?.delivery_radius ? item?.store.delivery_radius + ' Km ' : ""}
                                                        </span>
                                                    </div>
                                                </Link>
                                                <div className="d-flex justify-content-between">
                                                    {(item?.is_combo && item?.combo_categories?.length > 0) || item?.addoncategories?.length > 0 ? (
                                                        <Link to={'/single-product/' + item.id} className="d-flex flex-column ">
                                                            <div className="d-flex justify-content-between" >
                                                                <div className="fw-bolder mt-3 pt-1"
                                                                    style={{ color: 'grey', fontSize: "15px", }}  >
                                                                    Customizable
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    ) : ("")}
                                                    <Link to={'/single-product/' + item.id} className={parseInt(item.market_price) > parseInt(item?.selling_price) ? "d-flex flex-column " : "d-flex flex-column justify-content-end align-items-end"}>
                                                        {parseInt(item.market_price) > parseInt(item?.selling_price) ? (
                                                            <div className=" fw-bolder">
                                                                <s style={{ color: "#FF9A62", fontSize: "11px", }} >
                                                                    Rs {item?.market_price}
                                                                </s>
                                                            </div>) : ('')
                                                        }
                                                        {parseInt(item?.selling_price) > 0 ? (
                                                            <div className="d-flex justify-content-between" >
                                                                <div className=" text-black fw-bolder "
                                                                    style={{ fontSize: "16px", }}  >
                                                                    Rs {item?.selling_price}
                                                                </div>
                                                            </div>
                                                        ) : ("")}
                                                    </Link>
                                                    <div className="d-flex flex-column justify-content-between align-items-center mt-2  ">
                                                        {user && user.success > 0 ? (
                                                            <>
                                                                <>
                                                                    {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                        <>
                                                                            <ComboCustomization
                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                forceUpdate={this.forceStateUpdate} />
                                                                        </>
                                                                    ) : item?.addoncategories?.length > 0 ? (
                                                                        <>
                                                                            {/* {////// console.log(item, 'iteemmmmmm')} */}
                                                                            <Customization
                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                homeBtn={true}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                                <>
                                                                                    {cartProducts.filter((cp) => cp.id === item.id).map((product) =>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="top-food-add-min-btn" onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                removeProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}>
                                                                                                <AiOutlineMinus
                                                                                                    style={{
                                                                                                        color: "green",
                                                                                                    }}
                                                                                                    size={14}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="top-food-count ms-2 me-2">
                                                                                                {product.quantity}
                                                                                            </div>
                                                                                            <div className="top-food-add-min-btn" onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.forceStateUpdate();
                                                                                            }}>
                                                                                                <AiOutlinePlus
                                                                                                    style={{
                                                                                                        color: "green",
                                                                                                    }}
                                                                                                    size={14}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="top-food-add-min-btn" onClick={() => {
                                                                                        item.quantity = 1;
                                                                                        addProduct(item);
                                                                                        this.forceStateUpdate();
                                                                                    }} >
                                                                                        <AiOutlinePlus
                                                                                            style={{
                                                                                                color: "green",
                                                                                            }}
                                                                                            size={14}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>

                                                            </>
                                                        ) : (
                                                            <Link to="/signUp">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="top-food-add-min-btn">
                                                                        <AiOutlinePlus
                                                                            style={{
                                                                                color: "green",
                                                                            }}
                                                                            size={14}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    : ''
                }
                <FloatCart float={this.state.float} isRunning={this.state.isRunning} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    topFoods: state.home.topFoods,
    user: state.user.user,
    cartProducts: state.cart.products
});

export default connect(mapStateToProps, { getTopFood, toggleFavItem, addProduct, removeProduct })(TopFood);

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithSearch from "../../Elements/MobileBackButtonWithSearch";
import { BsDot, BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";
import { Rating } from "react-simple-star-rating";
import { AiOutlineHeart, AiFillHeart, AiOutlinePlus, AiOutlineMinus, AiOutlineStop, AiFillShop, } from "react-icons/ai";
import { BottomSheet } from "react-spring-bottom-sheet";
import image from "../../../assets/images/noitems.png";
import { Link } from "react-router-dom";
import { RiHeart3Line, RiShareForwardLine, RiHeart3Fill, RiShareFill } from "react-icons/ri";
import { GET_SINGLE_STORE_ITEMS_URL, IMAGE_BASE_URL, ITEM_SHARE_URL, PLACE_HOLDER_IMAGE } from "../../../api";
import ProgressiveImage from "react-progressive-image";
import ContentLoader from "react-content-loader";
import { getSingleStore } from "../../../redux/mobile/mobileSingleStore/action";
import { toggleFavItem } from "../../../redux/mobile/item/action";
import { addProduct, removeProduct, updateStore } from "../../../redux/mobile/cart/action";
import FloatCart from "../Cart/FloatCart";
import Truncate from "react-truncate";
import { MdOutlineContentCopy, MdDone, MdStars, MdShoppingBasket, } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ShareSocial } from 'react-share-social'
import { RWebShare } from "react-web-share";
import Customization from "../Customization";
import discount from "../../../assets/images/discount.png";
import { IoBicycle, IoFastFoodSharp } from "react-icons/io5";

import { GiBurn } from "react-icons/gi";
import { FaHandHoldingMedical, FaPumpMedical } from "react-icons/fa";
import ComboCustomization from "../ComboCustomization";
import non_veg_icon from '../../../assets/images/non-veg-icon.png'
import veg_icon from '../../../assets/images/veg-icon.png'
import { IoMdEye } from "react-icons/io";
import StoreCard from "./StoreCard";
import { BiSearch } from "react-icons/bi";
import { CgChevronUp } from "react-icons/cg";
import { ImUpload2 } from "react-icons/im";
import Ink from "react-ink";
import axios from "axios";

export class Store extends Component {

    state = {
        cart: false,
        isveg: true,
        isnonveg: true,
        couponsheet: false,
        coupon: {},
        loading: false,
        id: this.props.match.params.id,
        itemcategory: this.props.match.params.category,
        rating: [],
        items: {},
        categoryitems: {},
        category_id: "",
        copied: false,
        copied_code: null,
        sharesheet: false,
        share: '',
        isRunning: false,
        coupons: [],
        float: false,
        noCategory: false,
        showFreeItemPopUp: false,
        searchinput: "", // New state variable for search input
        searchResults: [], // New state variable for filtered items
        showScrollToTop: false,
    };
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        this.setState({ loading: true });
        this.__fetchStoreItems()
        this.props.getSingleStore(this.state.id).then((response) => {
            if (response && response.payload && response.payload.success) {

                if (response.payload.store && response.payload.store?.items && response.payload.store?.items?.length > 0) {

                    updateStore(response.payload.store?.items[0]?.store);
                    localStorage.setItem('prevStoreIdForScroll', window.location.pathname);


                }

                this.setState({
                    loading: false,
                    // categoryitems: response.payload.store.items,
                    // items: response.payload.store.items,
                    // itemCategory: response.payload.itemCategory,
                    // coupons: response.payload.coupons
                });
                if (
                    this.state?.itemcategory !== "NULL" &&
                    this.state?.itemcategory !== undefined
                ) {
                    let itemcategory = parseInt(this.state.itemcategory);
                    this.setState(
                        { category_id: itemcategory },
                        // this.openMenu()
                    );
                }
            } else {
                ////// console.log("API error");
            }
        });
    }


    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll); // Clean up event listener
    }

    handleScroll = () => {
        if (window.scrollY > 300) { // Show button after scrolling 300px
            this.setState({ showScrollToTop: true });
        } else {
            this.setState({ showScrollToTop: false });
        }
    };

    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    searchItems = (input) => {
        const { categoryitems } = this.state;

        if (input.length > 2) {

            const searchResults = categoryitems?.filter((item) =>

                item?.name?.toLowerCase().includes(input.toLowerCase()) ||
                item?.description?.toLowerCase().includes(input.toLowerCase())
            );

            // console.log('====================================');
            // console.log("ivade");
            // console.log('====================================');
            this.setState({ searchResults });
        } else {
            this.setState({ searchResults: [] }); // Clear the filter if input length is less than 3
        }
    };

    toggleFavItem = (item) => {
        const { categoryitems } = this.state;
        const { user } = this.props;
        const singleValue = categoryitems.find((data) => data.id === item.id);
        const favUsers = singleValue.favorites.find(
            (fav) => fav.user_id === user.data.id
        );
        if (favUsers) {
            const favIndexs = singleValue.favorites.indexOf(favUsers);
            singleValue.favorites.splice(favIndexs, 1);
            this.setState({ categoryitems: categoryitems });
        } else {
            singleValue.favorites.push({ user_id: user.data.id });
            this.setState({ categoryitems: categoryitems });
        }
        this.props.toggleFavItem(this.props.user.data.auth_token, item.id);
    };
    // filterCategory = (category) => {
    //     // this.setState({ loading: true });
    //     category.forEach((elem, index) => {
    //         elem.addEventListener("click", this.scrollSmoothHandler());
    //       });
    // };
    openCoupon = (data) => {
        this.setState({ couponsheet: true, coupon: data, floatCart: true });
    };
    closeCoupon = () => {
        this.setState({ couponsheet: false, floatCart: false });
    };
    openMenu = () => {
        this.setState({ cart: true, floatCart: true });
    };
    closeMenu = () => {
        this.setState({ cart: false, floatCart: false });
    };
    openShare = (data) => {
        this.setState({ share: data });
    };
    closeShare = () => {
        this.setState({ sharesheet: false });
    };

    openFreeItemPopUp = () => {
        this.setState({ showFreeItemPopUp: true });
    };
    closeFreeItemPopUp = () => {
        this.setState({ showFreeItemPopUp: false });
    };
    toggleItemVeg = (type) => {
        if (type === "VEG") {
            if (this.state.isveg === false) {
                this.setState({ isveg: true });
            } else {
                if (this.state.isnonveg === true) {
                    this.setState({ isveg: false });
                } else {
                    this.setState({ isveg: true, isnonveg: true });
                }
            }
        } else {
            if (this.state.isnonveg === false) {
                this.setState({ isnonveg: true });
            } else {
                if (this.state.isveg === true) {
                    this.setState({ isnonveg: false });
                } else {
                    this.setState({ isnonveg: true, isveg: true });
                }
            }
        }
    };
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    floatCart = (on) => {
        this.setState({ float: on })
    }
    goToViolation = (id) => {
        const violation = document.getElementById(id + '-row-id');
        if (violation) {
            // ////// console.log(violation?.offsetTop, 'violation?.offsetTop')
            window.scrollTo({
                top: violation?.offsetTop - 22,
                behavior: "smooth"
            });
            this.setState({ cart: false, floatCart: false })
        } else {
            this.setState({ cart: false, noCategory: true, floatCart: false })
            setTimeout(() => {
                this.setState({ noCategory: false })

            }, 1000);
        }
    };

    handleFreeItemPopUp = () => {
        const { categoryitems } = this.state;

        // Directly check if there's any free item in the category items
        const hasFreeItems = categoryitems.some(item => item.is_free_item === 1);

        // Set state based on the presence of free items
        if (hasFreeItems) {
            this.setState({ showFreeItemPopUp: true });
        }
    };

    checkFreeItemAlreadyAdded = () => {
        const { cartProducts, } = this.props;

        return cartProducts.some(cp => cp.is_free_item === 1);

    }

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
        total = Math.round(total / count * 100) / 100;
        //// console.log('====================================');
        //// console.log((total / count * 100) / 100, 'total');
        //// console.log('====================================');
        return total;
    }

    __fetchStoreItems() {
        axios.post(GET_SINGLE_STORE_ITEMS_URL, { id: this.state.id })
            .then((response) => {
                if (response && response.data && response.data.success) {
                    const data = response.data;

                    if (data.store && data.store.items && data.store.items.length > 0) {
                        this.props.updateStore(data.store.items[0].store);
                        localStorage.setItem('prevStoreIdForScroll', window.location.pathname);
                    }

                    this.setState({
                        loading: false,
                        categoryitems: data.store.items,
                        items: data.store.items,
                        itemCategory: data.itemCategory,
                        coupons: data.store.coupons || []

                    });

                    if (this.state.itemcategory !== "NULL" && this.state.itemcategory !== undefined) {
                        let itemcategory = parseInt(this.state.itemcategory);
                        this.setState({ category_id: itemcategory });
                    }
                } else {
                    console.log("API error");
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
            });
    }

    render() {
        const { user, store, addProduct, cartProducts, removeProduct } = this.props;
        const { categoryitems, coupons, itemCategory, showFreeItemPopUp } = this.state;
        // console.log('====================================');
        // console.log(categoryitems?.length);
        // console.log('====================================');
        let showItems = [];
        let freeItems = [];
        let comboItems = []
        let itemsWithCoupons = [];
        if (this.state.isveg === true && this.state.isnonveg === false) {
            showItems = categoryitems.filter((item) => item.is_veg === 1 && item.is_free_item === 0);
        } else if (this.state.isveg === false && this.state.isnonveg === true) {
            showItems = categoryitems.filter((item) => item.is_veg === 0 && item.is_free_item === 0);
        } else {
            if (categoryitems?.length) {
                showItems = categoryitems.filter((item) => item.is_free_item === 0);
                freeItems = categoryitems.filter((item) => item.is_free_item === 1);
                comboItems = categoryitems.filter(item => item?.is_combo && item?.combo_categories?.length > 0);
                itemsWithCoupons = categoryitems.filter(item => item.coupons && item.coupons.length > 0);

            }
        }
        let shouldShowCombo = comboItems.length > 0;
        let filteredItems = shouldShowCombo && comboItems;
        filteredItems = filteredItems?.length > 0 && [...filteredItems, ...itemsWithCoupons];
        filteredItems = filteredItems?.length > 0 && filteredItems.filter((item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );

        return (
            <React.Fragment>
                {this.state.showScrollToTop && (
                    <button
                        onClick={this.scrollToTop}
                        style={{
                            position: "fixed",
                            bottom: cartProducts?.length > 0 ? "180px" : "100px ",
                            right: "20px",
                            backgroundColor: "#000",
                            color: "white",
                            border: "none",
                            padding: "10px 15px",
                            borderRadius: "50px",
                            cursor: "pointer",
                            zIndex: 1000,
                            height: "50px",
                            width: "50px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CgChevronUp size={30} />
                    </button>
                )}
                {this.state?.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="10" y="20" rx="5" ry="5" width="22" height="22" />
                            <rect x="13" y="55" rx="0" ry="0" width="140" height="30" />
                            <rect x="13" y="100" rx="0" ry="0" width="120" height="15" />
                            <rect x="200" y="61" rx="10" ry="10" width="30" height="30" />
                            <rect x="13" y="120" rx="5" ry="5" width="240" height="100" />
                            <rect x="13" y="230" rx="10" ry="10" width="120" height="40" />
                            <rect x="140" y="230" rx="10" ry="10" width="120" height="40" />
                            <rect x="130" y="290" rx="10" ry="10" width="30" height="20" />
                            <rect x="164" y="295" rx="0" ry="0" width="20" height="10" />
                            <rect x="200" y="290" rx="10" ry="10" width="30" height="20" />
                            <rect x="235" y="295" rx="0" ry="0" width="30" height="10" />
                            <rect x="13" y="320" rx="0" ry="0" width="87" height="15" />
                            <rect x="15" y="350" rx="10" ry="10" width="250" height="100" />
                            <rect x="15" y="460" rx="10" ry="10" width="250" height="100" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {store && store.store && (
                            <div className="text-black" style={{ paddingBottom: "18vh" }}>
                                <StoreCard
                                    store={store}
                                    history={this.props.history}
                                    ratingCount={this.ratingCount}
                                    openShare={this.openShare}
                                />
                                <div className={`px-4 ${!store?.store?.is_active && 'store-not-active-click'}`}>
                                    {coupons?.length > 0 ? (
                                        <div className="main-menu hidden-scroll-bar gap-3 mt-3" style={{ gridTemplateColumns: "repeat(" + (coupons?.length + 1) + ",1fr)", }}>
                                            {coupons?.map((coupon) => (


                                                coupon.coupon_type == 'STORE' &&

                                                <div key={coupon?.id} className="d-flex flex-row align-items-center coupon-store py-2 px-2" onClick={() => this.openCoupon(coupon)} >
                                                    <img src={discount} style={{ width: '23px' }} alt="discount" />

                                                    <div className="d-flex flex-shrink-1 flex-column ps-2">
                                                        <span style={{ fontWeight: 600 }} >
                                                            {coupon.discount_type === "FIXED" ? '₹' + coupon.coupon_discount + " Off" : coupon.coupon_discount + '%Off'}
                                                            {
                                                                coupon.max_discount && coupon.discount_type === "PERCENTAGE" && " Upto ₹" + coupon.max_discount
                                                            }
                                                        </span>
                                                        <span style={{ fontWeight: 600 }} >
                                                            Use{" "} <span style={{ fontWeight: 800 }} >{coupon?.code}</span> | Above ₹{coupon?.min_sub_total}
                                                        </span>
                                                    </div>
                                                </div>


                                            ))}
                                        </div>
                                    ) : ("")}
                                    <div className="d-flex justify-content-between w-full mt-3">
                                        <div className="d-flex flex-row align-items-center explore-menu-searchBar2 py-2 px-2">
                                            <div className="">
                                                <BiSearch
                                                    set="curved"
                                                    style={{
                                                        fontSize: "2em",
                                                        color: "#DA6317",
                                                    }}
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                className="mobile-inventory-search w-100 "
                                                style={{ paddingLeft: "1em" }}
                                                value={this.state.searchinput}

                                                // value={this.state.searchinput}

                                                placeholder="What are you looking for?"
                                                onChange={(event) => {
                                                    this.setState({ searchinput: event.target.value });
                                                    this.searchItems(event.target.value);
                                                }} />
                                        </div>
                                        {/* <div className="filter-image-exploremenu"
                                        onClick={() => this.openMenu()}>
                                        <img src={filter} alt="filter" />
                                    </div> */}
                                    </div>
                                    {/* veg or non-veg toggle */}
                                    {store?.store?.store_category_id === 1 && !store?.store?.is_veg ? (
                                        <div className="d-flex justify-content-end mt-3 px-3"  >
                                            <div style={{ paddingRight: "0.5em" }} onClick={() => this.toggleItemVeg("VEG")} >
                                                {this.state.isveg ? (
                                                    <BsToggleOn set="curved" style={{ fontSize: "2.4em", color: "green" }} />
                                                ) : (
                                                    <BsToggleOff set="curved" style={{ fontSize: "2.4em", color: "#D9D9D9" }} />
                                                )}
                                                <span className="ms-2" style={{ color: "#AFAEB5", fontWeight: "700", fontSize: "15px" }}>
                                                    Veg
                                                </span>
                                            </div>
                                            <div style={{ marginLeft: "1em" }} onClick={() => this.toggleItemVeg("NONVEG")} >
                                                {this.state.isnonveg ? (
                                                    <BsToggleOn set="curved" style={{ fontSize: "2.4em", color: "red" }} />
                                                ) : (
                                                    <BsToggleOff set="curved" style={{ fontSize: "2.4em", color: "#D9D9D9" }} />
                                                )}
                                                <span className="ms-2" style={{ color: "#AFAEB5", fontWeight: "700", fontSize: "15px", }} >
                                                    Non Veg
                                                </span>
                                            </div>
                                        </div>
                                    ) : ("")}
                                    {this.state.searchinput?.length >= 2 || this.state.searchResults?.length > 0 ? (
                                        <>
                                            <div className="description mt-2" ref={this.myRef}>
                                                <span className="pe-2">
                                                    <BiSearch className="mb-1" color="FF9A62" size={18} />

                                                </span>
                                                Search Results
                                            </div>
                                            {this.state.searchinput?.length >= 2 && this.state.searchResults?.length == 0 &&
                                                <div className="description text-muted mt-2 d-flex align-items-center justify-content-center" ref={this.myRef}>

                                                    No Items found
                                                </div>
                                            }
                                            {this.state.searchResults?.sort((a, b) => {
                                                // Check if combo_categories exist and get their lengths
                                                const aLength = a.combo_categories ? a.combo_categories.length : 0;
                                                const bLength = b.combo_categories ? b.combo_categories.length : 0;

                                                // Sort in ascending order by combo_categories length
                                                return bLength - aLength;
                                            })?.map((item) => (
                                                <div className="position-relative" style={{ paddingBottom: "6vw" }}>
                                                    <div className="store-menu px-3 mt-3 py-2 d-flex flex-row justify-content-between " style={{ position: 'sticky', zIndex: '10' }}>
                                                        <div className="flex-grow-1">
                                                            <Link to={"/single-product/" + item.id}  >
                                                                <div>
                                                                    <Truncate lines={2} style={{ color: "#09051C", fontWeight: "600", fontSize: "15px", }}>
                                                                        {item.name}
                                                                    </Truncate>
                                                                </div>
                                                                <div>
                                                                    <Truncate lines={2} style={{ color: "#B3B2B6" }}>
                                                                        {item.description}
                                                                    </Truncate>
                                                                </div>
                                                            </Link>
                                                            <div className="">
                                                                {item.ratings.length > 0 ?
                                                                    <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item.ratings, item.ratings.length)} readonly="true" />
                                                                    : null}
                                                            </div>
                                                            {item?.dealofdays?.length > 0 ?
                                                                item?.selling_price > 0 ? (
                                                                    <div className='d-flex gap-3'>
                                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                            Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                                        </span>
                                                                        <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                            Rs. {item?.selling_price}
                                                                        </s>
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9012' }}>
                                                                        {item.dealofdays[0].offer}% OFF
                                                                    </span>
                                                                ) :
                                                                <div className="d-flex">
                                                                    <Link to={"/single-product/" + item.id}  >
                                                                        <div>
                                                                            <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px" }} >
                                                                                Rs.{" "}
                                                                                {item?.selling_price ? item?.selling_price : 0.0}
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    {item?.market_price > 0 ? (
                                                                        <div className="px-1 py-1" style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", }} >
                                                                            Rs.
                                                                            {item.market_price}
                                                                        </div>
                                                                    ) : ("")}
                                                                </div>
                                                            }
                                                            {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                <div>
                                                                    <span style={{ "fontSize": "15px", "background": "#5fbf5f", "padding": "2px 15px", "position": "relative", "color": "#fff", "left": "-6%", "borderTopRightRadius": "10px", "borderBottomRightRadius": "10px" }}> Buy {item.combo_items_count} Get  {item.free_items_count}</span>
                                                                </div>
                                                            ) : item?.addoncategories?.length > 0 ? (
                                                                <div>
                                                                    <span style={{ fontSize: '13px' }}>Customisable</span>
                                                                </div>
                                                            ) : null}
                                                            <div className="d-flex " style={{ paddingBottom: "0.8em", }} >
                                                                {user && user.success > 0 ? (
                                                                    <div className="" onClick={() => this.toggleFavItem(item)}>
                                                                        {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                            <AiFillHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                        ) : (
                                                                            <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <Link to="/signUp">
                                                                        <div className="">
                                                                            <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                                {user && user.success > 0 ? (
                                                                    <div className="ms-4">
                                                                        <RWebShare
                                                                            data={{
                                                                                url: ITEM_SHARE_URL + '/single-product/' + item?.id,
                                                                                title: item?.name + " " + item?.description
                                                                            }}

                                                                            onClick={() => this.openShare(item)}

                                                                        >

                                                                            <RiShareForwardLine
                                                                                size={"2em"}
                                                                                color={"black"}
                                                                            />
                                                                        </RWebShare>
                                                                    </div>
                                                                ) : (
                                                                    <Link to="/signUp">
                                                                        <div className="ms-4">
                                                                            <RiShareForwardLine
                                                                                size={"2em"}
                                                                                color={"green"}
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                                            {item.image ?
                                                                <div className="position-relative">
                                                                    <ProgressiveImage
                                                                        delay={20}
                                                                        onClick={() => { window.location.href(`/single-product/"${item.id}`) }}
                                                                        src={IMAGE_BASE_URL + item.image}
                                                                        placeholder={PLACE_HOLDER_IMAGE}  >
                                                                        {(src, loading) => (
                                                                            <img
                                                                                className=""
                                                                                style={{ width: "8em", objectFit: "cover", height: "25vw", borderRadius: "15px", backgroundImage: `url(${PLACE_HOLDER_IMAGE})`, backgroundRepeat: "round", }}
                                                                                src={src}
                                                                                alt="main-item" />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                    {(item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                        <>
                                                                            {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    <ComboCustomization
                                                                                        floatCart={(e) => this.floatCart(e)}
                                                                                        product={item}
                                                                                        addPrsssoduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        storeBtn={true} />
                                                                                </div>
                                                                            ) : item?.addoncategories?.length > 0 ? (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    <Customization
                                                                                        floatCart={(e) => this.floatCart(e)}
                                                                                        product={item}
                                                                                        addProduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        storeBtn={true}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                        <>
                                                                                            {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                                <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                    <div className="top-food-add-min-btn"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            removeProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}  >
                                                                                                        <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                    </div>
                                                                                                    <div className="top-food-count ms-1 me-1">
                                                                                                        {product.quantity}
                                                                                                    </div>
                                                                                                    {item?.store?.store_category_id == 1 ? (
                                                                                                        <div className="top-food-add-min-btn"
                                                                                                            onClick={() => {
                                                                                                                item.quantity = 1;
                                                                                                                addProduct(item);
                                                                                                                this.handleFreeItemPopUp();
                                                                                                                this.forceStateUpdate();
                                                                                                            }}  >
                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                            {store.store.store_category.check_stock === 1 ?
                                                                                                                (item.stock > product.quantity ? (
                                                                                                                    <div className="top-food-add-min-btn"
                                                                                                                        onClick={() => {
                                                                                                                            item.quantity = 1;
                                                                                                                            addProduct(item);
                                                                                                                            this.handleFreeItemPopUp();

                                                                                                                            this.forceStateUpdate();
                                                                                                                        }}  >
                                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="top-food-add-min-btn">
                                                                                                                        <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                                    </div>
                                                                                                                )
                                                                                                                ) :

                                                                                                                (
                                                                                                                    <div className="top-food-add-min-btn"
                                                                                                                        onClick={() => {
                                                                                                                            item.quantity = 1;
                                                                                                                            addProduct(item);
                                                                                                                            this.handleFreeItemPopUp();

                                                                                                                            this.forceStateUpdate();
                                                                                                                        }}  >
                                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                    </div>
                                                                                                                )


                                                                                                            }





                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                            <div className="top-food-btn px-3"
                                                                                                onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    addProduct(item);
                                                                                                    this.handleFreeItemPopUp();

                                                                                                    this.forceStateUpdate();
                                                                                                }}    >
                                                                                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                <span style={{ color: "green" }}   >
                                                                                                    ADD
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                            }
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                                : (item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                    <>
                                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                <ComboCustomization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true} />
                                                                            </div>
                                                                        ) : item?.addoncategories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                <Customization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                    <>
                                                                                        {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                            <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                <div className="top-food-add-min-btn"
                                                                                                    onClick={() => {
                                                                                                        item.quantity = 1;
                                                                                                        removeProduct(item);
                                                                                                        this.forceStateUpdate();
                                                                                                    }}  >
                                                                                                    <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                </div>
                                                                                                <div className="top-food-count ms-1 me-1">
                                                                                                    {product.quantity}
                                                                                                </div>
                                                                                                {item?.store?.store_category_id == 1 ? (
                                                                                                    <div className="top-food-add-min-btn"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            addProduct(item);
                                                                                                            this.handleFreeItemPopUp();
                                                                                                            this.forceStateUpdate();
                                                                                                        }}  >
                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {product.stock > product.quantity ? (
                                                                                                            <div className="top-food-add-min-btn"
                                                                                                                onClick={() => {
                                                                                                                    item.quantity = 1;
                                                                                                                    addProduct(item);
                                                                                                                    this.handleFreeItemPopUp();
                                                                                                                    this.forceStateUpdate();
                                                                                                                }}  >
                                                                                                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="top-food-add-min-btn">
                                                                                                                <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                        <div className="top-food-btn px-3"
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.handleFreeItemPopUp();
                                                                                                this.forceStateUpdate();
                                                                                            }}    >
                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                            <span style={{ color: "green", }}   >
                                                                                                ADD
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <div className="d-flex align-items-center pt-2 ms-3 me-2 mt-1 fs-7 fw-bolder">

                                                                        <div className="text-danger">
                                                                            Out Of
                                                                            Stock
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    {item?.coupons?.length > 0 ?
                                                        <div className="w-100 position-absolute bottom-0 pt-3 px-3 fw-500 text-center mt-1" style={{ borderRadius: '0px 0px 15px 15px', backgroundColor: '#3e996f', color: '#fff', zIndex: '5' }}>
                                                            {item?.coupons[0]?.discount_type === "PERCENTAGE" ?
                                                                <span>
                                                                    {item?.coupons[0]?.coupon_discount}%
                                                                    <>
                                                                        {item?.coupons[0]?.min_sub_total > 0 &&
                                                                            <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                        }
                                                                    </> OFF USE :
                                                                    <span className="fw-800"> {item?.coupons[0]?.code} </span>
                                                                </span>
                                                                : item?.coupons[0]?.discount_type === "FIXED" ? (
                                                                    <span>
                                                                        RS.{item?.coupons[0]?.coupon_discount}
                                                                        <>
                                                                            {item?.coupons[0]?.min_sub_total > 0 &&
                                                                                <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                            }
                                                                        </> OFF USE

                                                                        <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                    </span>
                                                                ) : <span>
                                                                    RS.{item?.coupons[0]?.coupon_discount} OFF USE <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        : null}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}















































                                    {/* Offered Items */}
                                    <div className="mb-4">
                                        {filteredItems.length > 0 &&
                                            (
                                                <div className="description mt-2" ref={this.myRef}>
                                                    <span className="pe-2">
                                                        {store?.store?.store_category_id == 1 ? (
                                                            <IoFastFoodSharp className="mb-1" color="FF9A62" size={18} />
                                                        ) : store?.store?.store_category_id == 2 ?
                                                            <FaPumpMedical className="mb-1" color="FF9A62" size={18} />
                                                            :
                                                            <AiFillShop className="mb-1" color="FF9A62" size={18} />
                                                        }
                                                    </span>
                                                    Offer Items
                                                </div>
                                            )

                                        }





                                        {filteredItems.length > 0 && filteredItems.map((item, index) => (
                                            <div className="position-relative" style={{ paddingBottom: "6vw" }}>
                                                <div className="store-menu px-3 mt-3 py-2 d-flex flex-row justify-content-between " style={{ position: 'sticky', zIndex: '10' }}>
                                                    <div className="flex-grow-1">
                                                        <Link to={"/single-product/" + item.id}  >
                                                            <div>
                                                                <Truncate lines={2} style={{ color: "#09051C", fontWeight: "600", fontSize: "15px", }}>
                                                                    {item.name}
                                                                </Truncate>
                                                            </div>
                                                            <div>
                                                                <Truncate lines={2} style={{ color: "#B3B2B6" }}>
                                                                    {item.description}
                                                                </Truncate>
                                                            </div>
                                                        </Link>
                                                        <div className="">
                                                            {item.ratings.length > 0 ?
                                                                <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item.ratings, item.ratings.length)} readonly="true" />
                                                                : null}
                                                        </div>
                                                        {item?.dealofdays?.length > 0 ?
                                                            item?.selling_price > 0 ? (
                                                                <div className='d-flex gap-3'>
                                                                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                        Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                                    </span>
                                                                    <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                        Rs. {item?.selling_price}
                                                                    </s>
                                                                </div>
                                                            ) : (
                                                                <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9012' }}>
                                                                    {item.dealofdays[0].offer}% OFF
                                                                </span>
                                                            ) :
                                                            <div className="d-flex">
                                                                <Link to={"/single-product/" + item.id}  >
                                                                    <div>
                                                                        <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px" }} >
                                                                            Rs.{" "}
                                                                            {item?.selling_price ? item?.selling_price : 0.0}
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                                {item?.market_price > 0 ? (
                                                                    <div className="px-1 py-1" style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", }} >
                                                                        Rs.
                                                                        {item.market_price}
                                                                    </div>
                                                                ) : ("")}
                                                            </div>
                                                        }
                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                            <div>
                                                                <span style={{ "fontSize": "15px", "background": "#5fbf5f", "padding": "2px 15px", "position": "relative", "color": "#fff", "left": "-6%", "borderTopRightRadius": "10px", "borderBottomRightRadius": "10px" }}>  {item?.buy1_text ? item?.buy1_text : `Buy ${item.combo_items_count} Get ${item.free_items_count}`}
                                                                </span>
                                                            </div>
                                                        ) : item?.addoncategories?.length > 0 ? (
                                                            <div>
                                                                <span style={{ fontSize: '13px' }}>Customisable</span>
                                                            </div>
                                                        ) : null}
                                                        <div className="d-flex " style={{ paddingBottom: "0.8em", }} >
                                                            {user && user.success > 0 ? (
                                                                <div className="" onClick={() => this.toggleFavItem(item)}>
                                                                    {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                        <AiFillHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                    ) : (
                                                                        <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                    )}
                                                                </div>
                                                            ) : (
                                                                <Link to="/signUp">
                                                                    <div className="">
                                                                        <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                    </div>
                                                                </Link>
                                                            )}
                                                            {user && user.success > 0 ? (
                                                                <div className="ms-4">
                                                                    <RWebShare
                                                                        data={{
                                                                            url: ITEM_SHARE_URL + '/single-product/' + item?.id,
                                                                            title: item?.name + " " + item?.description
                                                                        }}

                                                                        onClick={() => this.openShare(item)}

                                                                    >

                                                                        <RiShareForwardLine
                                                                            size={"2em"}
                                                                            color={"black"}
                                                                        />
                                                                    </RWebShare>
                                                                </div>
                                                            ) : (
                                                                <Link to="/signUp">
                                                                    <div className="ms-4">
                                                                        <RiShareForwardLine
                                                                            size={"2em"}
                                                                            color={"black"}
                                                                        />
                                                                    </div>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                                        {item.image ?
                                                            <div className="position-relative">
                                                                <ProgressiveImage
                                                                    delay={20}
                                                                    onClick={() => { window.location.href(`/single-product/"${item.id}`) }}
                                                                    src={IMAGE_BASE_URL + item.image}
                                                                    placeholder={PLACE_HOLDER_IMAGE}  >
                                                                    {(src, loading) => (
                                                                        <img
                                                                            className=""
                                                                            style={{ width: "8em", objectFit: "cover", height: "25vw", borderRadius: "15px", backgroundImage: `url(${PLACE_HOLDER_IMAGE})`, backgroundRepeat: "round", }}
                                                                            src={src}
                                                                            alt="main-item" />
                                                                    )}
                                                                </ProgressiveImage>
                                                                {(item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                    <>
                                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                <ComboCustomization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true} />
                                                                            </div>
                                                                        ) : item?.addoncategories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                <Customization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                    <>
                                                                                        {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                            <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                <div className="top-food-add-min-btn"
                                                                                                    onClick={() => {
                                                                                                        item.quantity = 1;
                                                                                                        removeProduct(item);
                                                                                                        this.forceStateUpdate();
                                                                                                    }}  >
                                                                                                    <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                </div>
                                                                                                <div className="top-food-count ms-1 me-1">
                                                                                                    {product.quantity}
                                                                                                </div>
                                                                                                {item?.store?.store_category_id == 1 ? (
                                                                                                    <div className="top-food-add-min-btn"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            addProduct(item);
                                                                                                            this.handleFreeItemPopUp();
                                                                                                            this.forceStateUpdate();
                                                                                                        }}  >
                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>

                                                                                                        {store.store.store_category.check_stock === 1 ?
                                                                                                            (item.stock > product.quantity ? (
                                                                                                                <div className="top-food-add-min-btn"
                                                                                                                    onClick={() => {
                                                                                                                        item.quantity = 1;
                                                                                                                        addProduct(item);
                                                                                                                        this.handleFreeItemPopUp();

                                                                                                                        this.forceStateUpdate();
                                                                                                                    }}  >
                                                                                                                    <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div className="top-food-add-min-btn">
                                                                                                                    <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                                </div>
                                                                                                            )
                                                                                                            ) :

                                                                                                            (
                                                                                                                <div className="top-food-add-min-btn"
                                                                                                                    onClick={() => {
                                                                                                                        item.quantity = 1;
                                                                                                                        addProduct(item);
                                                                                                                        this.handleFreeItemPopUp();

                                                                                                                        this.forceStateUpdate();
                                                                                                                    }}  >
                                                                                                                    <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                </div>
                                                                                                            )


                                                                                                        }





                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                        <div className="top-food-btn px-3"
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.handleFreeItemPopUp();

                                                                                                this.forceStateUpdate();
                                                                                            }}    >
                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                            <span style={{ color: "green", }}   >
                                                                                                ADD
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                            : (item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                <>
                                                                    {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                            <ComboCustomization
                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                storeBtn={true} />
                                                                        </div>
                                                                    ) : item?.addoncategories?.length > 0 ? (
                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                            <Customization
                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                product={item}
                                                                                addProduct={addProduct}
                                                                                forceUpdate={this.forceStateUpdate}
                                                                                storeBtn={true}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                            {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                <>
                                                                                    {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                        <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                            <div className="top-food-add-min-btn"
                                                                                                onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    removeProduct(item);
                                                                                                    this.forceStateUpdate();
                                                                                                }}  >
                                                                                                <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                            </div>
                                                                                            <div className="top-food-count ms-1 me-1">
                                                                                                {product.quantity}
                                                                                            </div>
                                                                                            {item?.store?.store_category_id == 1 ? (
                                                                                                <div className="top-food-add-min-btn"
                                                                                                    onClick={() => {
                                                                                                        item.quantity = 1;
                                                                                                        addProduct(item);
                                                                                                        this.handleFreeItemPopUp();
                                                                                                        this.forceStateUpdate();
                                                                                                    }}  >
                                                                                                    <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                </div>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {product.stock > product.quantity ? (
                                                                                                        <div className="top-food-add-min-btn"
                                                                                                            onClick={() => {
                                                                                                                item.quantity = 1;
                                                                                                                addProduct(item);
                                                                                                                this.handleFreeItemPopUp();
                                                                                                                this.forceStateUpdate();
                                                                                                            }}  >
                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div className="top-food-add-min-btn">
                                                                                                            <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                    <div className="top-food-btn px-3"
                                                                                        onClick={() => {
                                                                                            item.quantity = 1;
                                                                                            addProduct(item);
                                                                                            this.handleFreeItemPopUp();
                                                                                            this.forceStateUpdate();
                                                                                        }}    >
                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                        <span style={{ color: "green", }}   >
                                                                                            ADD
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                    }
                                                                </>
                                                            ) : (
                                                                <div className="d-flex align-items-center pt-2 ms-3 me-2 mt-1 fs-7 fw-bolder">
                                                                    {/* <div className="top-food-btn px-3">
                                                                        <AiOutlineStop
                                                                            style={{
                                                                                color: "#fff",
                                                                            }}
                                                                            size={
                                                                                16
                                                                            }
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                color: "#fff",
                                                                            }}
                                                                        >
                                                                            ADD
                                                                        </span>
                                                                    </div> */}
                                                                    <div className="text-danger">
                                                                        Out Of
                                                                        Stock
                                                                    </div>
                                                                    {/* <Ink duration={500} style={{ color: "#aaa" }} /> */}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                {item?.coupons?.length > 0 ?
                                                    <div className="w-100 position-absolute bottom-0 pt-3 px-3 fw-500 text-center mt-1" style={{ borderRadius: '0px 0px 15px 15px', backgroundColor: '#3e996f', color: '#fff', zIndex: '5' }}>
                                                        {item?.coupons[0]?.discount_type === "PERCENTAGE" ?
                                                            <span>
                                                                <span className="fw-800">{item?.coupons[0]?.coupon_discount}</span>% OFF
                                                                <>
                                                                    {item?.coupons[0]?.max_discount > 0 &&
                                                                        <span> UP TO
                                                                            <span className="fw-800"> ₹{item?.coupons[0]?.max_discount}</span>
                                                                        </span>

                                                                    }
                                                                </>  USE :
                                                                <span className="fw-800"> {item?.coupons[0]?.code} </span>
                                                            </span>
                                                            : item?.coupons[0]?.discount_type === "FIXED" ? (
                                                                <span>
                                                                    <span className="fw-800">₹{item?.coupons[0]?.coupon_discount}</span> OFF
                                                                    <>
                                                                        {item?.coupons[0]?.max_discount > 0 &&
                                                                            <span> UP TO
                                                                                <span className="fw-800"> ₹{item?.coupons[0]?.max_discount}</span>
                                                                            </span>

                                                                        }
                                                                    </>  USE :
                                                                    <span className="fw-800"> {item?.coupons[0]?.code} </span>
                                                                </span>
                                                            ) : <span>
                                                                RS.{item?.coupons[0]?.coupon_discount} OFF USE <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                            </span>
                                                        }
                                                    </div>
                                                    : null}
                                            </div>
                                        ))}
                                    </div>




















                                    {/* popular items */}
                                    {showItems?.length > 0 && showItems?.filter((data) => (data.is_popular !== 0))?.length > 0 ? (
                                        <>
                                            <div className="description mt-2" ref={this.myRef}>
                                                <span className="pe-2">
                                                    {store?.store?.store_category_id == 1 ? (
                                                        <IoFastFoodSharp className="mb-1" color="FF9A62" size={18} />
                                                    ) : store?.store?.store_category_id == 2 ?
                                                        <FaPumpMedical className="mb-1" color="FF9A62" size={18} />
                                                        :
                                                        <AiFillShop className="mb-1" color="FF9A62" size={18} />
                                                    }
                                                </span>
                                                Popular Items
                                            </div>
                                            {showItems?.filter((data) => (data.is_popular !== 0))?.sort((a, b) => {
                                                // Check if combo_categories exist and get their lengths
                                                const aLength = a.combo_categories ? a.combo_categories.length : 0;
                                                const bLength = b.combo_categories ? b.combo_categories.length : 0;

                                                // Sort in ascending order by combo_categories length
                                                return bLength - aLength;
                                            })?.map((item) => (
                                                <div className="position-relative" style={{ paddingBottom: "6vw" }}>
                                                    <div className="store-menu px-3 mt-3 py-2 d-flex flex-row justify-content-between " style={{ position: 'sticky', zIndex: '10' }}>
                                                        <div className="flex-grow-1">
                                                            <Link to={"/single-product/" + item.id}  >
                                                                <div>
                                                                    <Truncate lines={2} style={{ color: "#09051C", fontWeight: "600", fontSize: "15px", }}>
                                                                        {item.name}
                                                                    </Truncate>
                                                                </div>
                                                                <div>
                                                                    <Truncate lines={2} style={{ color: "#B3B2B6" }}>
                                                                        {item.description}
                                                                    </Truncate>
                                                                </div>
                                                            </Link>
                                                            <div className="">
                                                                {item.ratings.length > 0 ?
                                                                    <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item.ratings, item.ratings.length)} readonly="true" />
                                                                    : null}
                                                            </div>
                                                            {item?.dealofdays?.length > 0 ?
                                                                item?.selling_price > 0 ? (
                                                                    <div className='d-flex gap-3'>
                                                                        <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                            Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                                        </span>
                                                                        <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                            Rs. {item?.selling_price}
                                                                        </s>
                                                                    </div>
                                                                ) : (
                                                                    <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9012' }}>
                                                                        {item.dealofdays[0].offer}% OFF
                                                                    </span>
                                                                ) :
                                                                <div className="d-flex">
                                                                    <Link to={"/single-product/" + item.id}  >
                                                                        <div>
                                                                            <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px" }} >
                                                                                Rs.{" "}
                                                                                {item?.selling_price ? item?.selling_price : 0.0}
                                                                            </span>
                                                                        </div>
                                                                    </Link>
                                                                    {item?.market_price > 0 ? (
                                                                        <div className="px-1 py-1" style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", }} >
                                                                            Rs.
                                                                            {item.market_price}
                                                                        </div>
                                                                    ) : ("")}
                                                                </div>
                                                            }
                                                            {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                <div>
                                                                    <span style={{ "fontSize": "15px", "background": "#5fbf5f", "padding": "2px 15px", "position": "relative", "color": "#fff", "left": "-6%", "borderTopRightRadius": "10px", "borderBottomRightRadius": "10px" }}> Buy {item.combo_items_count} Get  {item.free_items_count}</span>
                                                                </div>
                                                            ) : item?.addoncategories?.length > 0 ? (
                                                                <div>
                                                                    <span style={{ fontSize: '13px' }}>Customisable</span>
                                                                </div>
                                                            ) : null}
                                                            <div className="d-flex " style={{ paddingBottom: "0.8em", }} >
                                                                {user && user.success > 0 ? (
                                                                    <div className="" onClick={() => this.toggleFavItem(item)}>
                                                                        {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                            <AiFillHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                        ) : (
                                                                            <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <Link to="/signUp">
                                                                        <div className="">
                                                                            <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                                {user && user.success > 0 ? (
                                                                    <div className="ms-4">
                                                                        <RWebShare
                                                                            data={{
                                                                                url: ITEM_SHARE_URL + '/single-product/' + item?.id,
                                                                                title: item?.name + " " + item?.description
                                                                            }}

                                                                            onClick={() => this.openShare(item)}

                                                                        >

                                                                            <RiShareForwardLine
                                                                                size={"2em"}
                                                                                color={"black"}
                                                                            />
                                                                        </RWebShare>
                                                                    </div>
                                                                ) : (
                                                                    <Link to="/signUp">
                                                                        <div className="ms-4">
                                                                            <RiShareForwardLine
                                                                                size={"2em"}
                                                                                color={"green"}
                                                                            />
                                                                        </div>
                                                                    </Link>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                                            {item.image ?
                                                                <div className="position-relative">
                                                                    <ProgressiveImage
                                                                        delay={20}
                                                                        onClick={() => { window.location.href(`/single-product/"${item.id}`) }}
                                                                        src={IMAGE_BASE_URL + item.image}
                                                                        placeholder={PLACE_HOLDER_IMAGE}  >
                                                                        {(src, loading) => (
                                                                            <img
                                                                                className=""
                                                                                style={{ width: "8em", objectFit: "cover", height: "25vw", borderRadius: "15px", backgroundImage: `url(${PLACE_HOLDER_IMAGE})`, backgroundRepeat: "round", }}
                                                                                src={src}
                                                                                alt="main-item" />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                    {(item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                        <>
                                                                            {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    <ComboCustomization
                                                                                        floatCart={(e) => this.floatCart(e)}
                                                                                        product={item}
                                                                                        addPrsssoduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        storeBtn={true} />
                                                                                </div>
                                                                            ) : item?.addoncategories?.length > 0 ? (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    <Customization
                                                                                        floatCart={(e) => this.floatCart(e)}
                                                                                        product={item}
                                                                                        addProduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        storeBtn={true}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                    {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                        <>
                                                                                            {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                                <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                    <div className="top-food-add-min-btn"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            removeProduct(item);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}  >
                                                                                                        <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                    </div>
                                                                                                    <div className="top-food-count ms-1 me-1">
                                                                                                        {product.quantity}
                                                                                                    </div>
                                                                                                    {item?.store?.store_category_id == 1 ? (
                                                                                                        <div className="top-food-add-min-btn"
                                                                                                            onClick={() => {
                                                                                                                item.quantity = 1;
                                                                                                                addProduct(item);
                                                                                                                this.handleFreeItemPopUp();
                                                                                                                this.forceStateUpdate();
                                                                                                            }}  >
                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                            {store.store.store_category.check_stock === 1 ?
                                                                                                                (item.stock > product.quantity ? (
                                                                                                                    <div className="top-food-add-min-btn"
                                                                                                                        onClick={() => {
                                                                                                                            item.quantity = 1;
                                                                                                                            addProduct(item);
                                                                                                                            this.handleFreeItemPopUp();

                                                                                                                            this.forceStateUpdate();
                                                                                                                        }}  >
                                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div className="top-food-add-min-btn">
                                                                                                                        <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                                    </div>
                                                                                                                )
                                                                                                                ) :

                                                                                                                (
                                                                                                                    <div className="top-food-add-min-btn"
                                                                                                                        onClick={() => {
                                                                                                                            item.quantity = 1;
                                                                                                                            addProduct(item);
                                                                                                                            this.handleFreeItemPopUp();

                                                                                                                            this.forceStateUpdate();
                                                                                                                        }}  >
                                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                    </div>
                                                                                                                )


                                                                                                            }





                                                                                                        </>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                            <div className="top-food-btn px-3"
                                                                                                onClick={() => {
                                                                                                    item.quantity = 1;
                                                                                                    addProduct(item);
                                                                                                    this.handleFreeItemPopUp();

                                                                                                    this.forceStateUpdate();
                                                                                                }}    >
                                                                                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                <span style={{ color: "green" }}   >
                                                                                                    ADD
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                            }
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                                : (item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                    <>
                                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                <ComboCustomization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true} />
                                                                            </div>
                                                                        ) : item?.addoncategories?.length > 0 ? (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                <Customization
                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                    product={item}
                                                                                    addProduct={addProduct}
                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                    storeBtn={true}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="position-absolute" style={{ right: '35px' }}>
                                                                                {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                    <>
                                                                                        {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                            <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                <div className="top-food-add-min-btn"
                                                                                                    onClick={() => {
                                                                                                        item.quantity = 1;
                                                                                                        removeProduct(item);
                                                                                                        this.forceStateUpdate();
                                                                                                    }}  >
                                                                                                    <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                </div>
                                                                                                <div className="top-food-count ms-1 me-1">
                                                                                                    {product.quantity}
                                                                                                </div>
                                                                                                {item?.store?.store_category_id == 1 ? (
                                                                                                    <div className="top-food-add-min-btn"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            addProduct(item);
                                                                                                            this.handleFreeItemPopUp();
                                                                                                            this.forceStateUpdate();
                                                                                                        }}  >
                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {product.stock > product.quantity ? (
                                                                                                            <div className="top-food-add-min-btn"
                                                                                                                onClick={() => {
                                                                                                                    item.quantity = 1;
                                                                                                                    addProduct(item);
                                                                                                                    this.handleFreeItemPopUp();
                                                                                                                    this.forceStateUpdate();
                                                                                                                }}  >
                                                                                                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="top-food-add-min-btn">
                                                                                                                <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                ) : (
                                                                                    <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                        <div className="top-food-btn px-3"
                                                                                            onClick={() => {
                                                                                                item.quantity = 1;
                                                                                                addProduct(item);
                                                                                                this.handleFreeItemPopUp();
                                                                                                this.forceStateUpdate();
                                                                                            }}    >
                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                            <span style={{ color: "green", }}   >
                                                                                                ADD
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <div className="d-flex align-items-center pt-2 ms-3 me-2 mt-1 fs-7 fw-bolder">

                                                                        <div className="text-danger">
                                                                            Out Of
                                                                            Stock
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    {item?.coupons?.length > 0 ?
                                                        <div className="w-100 position-absolute bottom-0 pt-3 px-3 fw-500 text-center mt-1" style={{ borderRadius: '0px 0px 15px 15px', backgroundColor: '#3e996f', color: '#fff', zIndex: '5' }}>
                                                            {item?.coupons[0]?.discount_type === "PERCENTAGE" ?
                                                                <span>
                                                                    {item?.coupons[0]?.coupon_discount}%
                                                                    <>
                                                                        {item?.coupons[0]?.min_sub_total > 0 &&
                                                                            <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                        }
                                                                    </> OFF USE :
                                                                    <span className="fw-800"> {item?.coupons[0]?.code} </span>
                                                                </span>
                                                                : item?.coupons[0]?.discount_type === "FIXED" ? (
                                                                    <span>
                                                                        RS.{item?.coupons[0]?.coupon_discount}
                                                                        <>
                                                                            {item?.coupons[0]?.min_sub_total > 0 &&
                                                                                <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                            }
                                                                        </> OFF USE

                                                                        <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                    </span>
                                                                ) : <span>
                                                                    RS.{item?.coupons[0]?.coupon_discount} OFF USE <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        : null}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}




                                    {/* items mapping */}
                                    {showItems?.length > 0 && showItems?.filter((data) => (data.is_popular === 0))?.sort((a, b) => {
                                        // Check if combo_categories exist and get their lengths
                                        const aLength = a.combo_categories ? a.combo_categories.length : 0;
                                        const bLength = b.combo_categories ? b.combo_categories.length : 0;

                                        // Sort in ascending order by combo_categories length
                                        return bLength - aLength;
                                    })?.length > 0 ? (
                                        <>
                                            {itemCategory.map((category, index) => (
                                                <>
                                                    {showItems.filter((data) => (data.item_category_id === category.id && data.is_popular === 0)) && showItems.filter((data) => (data.item_category_id === category.id && data.is_popular === 0)).length > 0 && (
                                                        <div key={category?.id} className="description mt-3" style={{ fontSize: '20px' }} id={category.id + "-row-id"}>
                                                            <span className="pe-2">
                                                                {store?.store?.store_category_id == 1 ? (
                                                                    <GiBurn className="mb-1" color="FF9A62" size={15} />
                                                                ) : store?.store?.store_category_id == 2 ?
                                                                    <FaHandHoldingMedical className="mb-1" color="FF9A62" size={18} />
                                                                    :
                                                                    <MdShoppingBasket className="mb-1" color="FF9A62" size={18} />
                                                                }
                                                            </span> {category.name}
                                                        </div>
                                                    )}
                                                    <div>
                                                        {showItems.filter((data) => (data.item_category_id === category.id && data.is_popular === 0)).length > 0 && showItems.filter((data) => (data.item_category_id === category.id && data.is_popular === 0))?.map((item) => (
                                                            <div className="position-relative" style={{ paddingBottom: "6vw" }}>
                                                                <div className="store-menu px-3 mt-3 py-2 d-flex flex-row justify-content-between " style={{ position: 'sticky', zIndex: '10' }}>
                                                                    <div className="flex-grow-1 w-100">
                                                                        <Link to={"/single-product/" + item.id} className="w-100">
                                                                            <div className="d-flex align-items-center">
                                                                                {!item?.is_none ?
                                                                                    <img src={item?.is_veg ? veg_icon : non_veg_icon} style={{ width: "1rem" }} />
                                                                                    : null}
                                                                                <Truncate lines={2} style={{ color: "#09051C", fontWeight: "600", fontSize: "15px", marginLeft: !item?.is_none ? "5px" : "0px" }}>
                                                                                    {item.name}
                                                                                </Truncate>
                                                                            </div>
                                                                            <div>
                                                                                <Truncate lines={2} style={{ color: "#B3B2B6" }}>
                                                                                    {item.description}
                                                                                </Truncate>
                                                                            </div>
                                                                        </Link>
                                                                        <div className="">
                                                                            {item.ratings.length > 0 ?
                                                                                <Rating size={"1em"} className="mobile-rate-star" initialValue={this.ratingCount(item.ratings, item.ratings.length)} readonly="true" />
                                                                                : null}
                                                                        </div>
                                                                        {item?.dealofdays?.length > 0 ?
                                                                            item?.selling_price > 0 ? (
                                                                                <div className='d-flex gap-3'>
                                                                                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                        Rs. {item?.selling_price - (item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100)}
                                                                                    </span>
                                                                                    <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                        Rs. {item?.selling_price}
                                                                                    </s>
                                                                                </div>
                                                                            ) : (
                                                                                <span style={{ fontSize: "14px", fontWeight: "600", color: '#FF9012' }}>
                                                                                    {item.dealofdays[0].offer}% OFF
                                                                                </span>
                                                                            ) :
                                                                            <div className="d-flex">
                                                                                <Link to={"/single-product/" + item.id}  >
                                                                                    <div>
                                                                                        <span style={{ fontWeight: "600", color: "#09051C", fontSize: "16px" }} >
                                                                                            Rs.{" "}
                                                                                            {item?.selling_price ? item?.selling_price : 0.0}
                                                                                        </span>
                                                                                    </div>
                                                                                </Link>
                                                                                {item?.market_price > 0 ? (
                                                                                    <div className="px-1 py-1" style={{ textDecorationLine: "line-through", color: "#FF9012", fontSize: "13px", fontWeight: "600", }} >
                                                                                        Rs.
                                                                                        {item.market_price}
                                                                                    </div>
                                                                                ) : ("")}
                                                                            </div>
                                                                        }
                                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                            <div>
                                                                                <span style={{ "fontSize": "15px", "background": "#5fbf5f", "padding": "2px 15px", "position": "relative", "color": "#fff", "left": "-6%", "borderTopRightRadius": "10px", "borderBottomRightRadius": "10px" }}> Buy {item.combo_items_count} Get  {item.free_items_count}</span>
                                                                            </div>
                                                                        ) : item?.addoncategories?.length > 0 ? (
                                                                            <div>
                                                                                <span style={{ fontSize: '13px' }}>Customisable</span>
                                                                            </div>
                                                                        ) : null}
                                                                        {/* {////// console.log(item)} */}
                                                                        <div className="d-flex " style={{ paddingBottom: "0.8em", }}>
                                                                            {user && user.success > 0 ? (
                                                                                <div className="" onClick={() => this.toggleFavItem(item)}>
                                                                                    {item?.favorites?.length > 0 && item?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                                                        <AiFillHeart style={{ color: "#FF9012", }} size={"2em"} />
                                                                                    ) : (
                                                                                        <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Link to="/signUp">
                                                                                    <div className="">
                                                                                        <AiOutlineHeart style={{ color: "black", }} size={"2em"} />
                                                                                    </div>
                                                                                </Link>
                                                                            )}
                                                                            {user && user.success > 0 ? (

                                                                                <div className="ms-4">
                                                                                    <RWebShare
                                                                                        data={{
                                                                                            url: ITEM_SHARE_URL + '/single-product/' + this.state.share?.id,
                                                                                            title: this.state.share?.name + " " + this.state.share?.description
                                                                                        }}

                                                                                        onClick={() => this.openShare(item)}

                                                                                    >

                                                                                        <RiShareForwardLine
                                                                                            size={"2em"}
                                                                                            color={"black"}
                                                                                        />
                                                                                    </RWebShare>
                                                                                </div>

                                                                            ) : (
                                                                                <Link to="/signUp">
                                                                                    <div className="ms-4">
                                                                                        <RiShareForwardLine
                                                                                            size={"2em"}
                                                                                            color={"green"}
                                                                                        />
                                                                                    </div>
                                                                                </Link>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-column align-items-center justify-content-center mb-4">
                                                                        {item.image ?
                                                                            <div className=" position-relative"  >
                                                                                <Link to={"/single-product/" + item.id}  >
                                                                                    <ProgressiveImage
                                                                                        delay={20}
                                                                                        src={IMAGE_BASE_URL + item?.image}
                                                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                                                    >
                                                                                        {(src, loading) => (
                                                                                            <img
                                                                                                className=""
                                                                                                style={{
                                                                                                    width: "8em",
                                                                                                    objectFit:
                                                                                                        "cover",
                                                                                                    height: "25vw",
                                                                                                    borderRadius:
                                                                                                        "15px",
                                                                                                    backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                                                                    backgroundRepeat:
                                                                                                        "round",
                                                                                                }}
                                                                                                src={src}
                                                                                                alt="main-item"
                                                                                            />
                                                                                        )}
                                                                                    </ProgressiveImage>
                                                                                </Link>

                                                                                {(item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                                    <>

                                                                                        {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                                            <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                                <ComboCustomization
                                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                                    product={item}
                                                                                                    addProduct={addProduct}
                                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                                    storeBtn={true} />
                                                                                            </div>
                                                                                        ) : item?.addoncategories?.length > 0 ? (
                                                                                            <div className="position-absolute" style={{ bottom: '-15px', right: '20px' }}>
                                                                                                <Customization
                                                                                                    floatCart={(e) => this.floatCart(e)}
                                                                                                    product={item}
                                                                                                    addProduct={addProduct}
                                                                                                    forceUpdate={this.forceStateUpdate}
                                                                                                    storeBtn={true}
                                                                                                />
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="position-absolute text-center px-3" style={{ width: '100%', bottom: '-15px' }}>
                                                                                                {cartProducts.find((cp) => cp.id === item.id) !== undefined ? (
                                                                                                    <>
                                                                                                        {cartProducts.filter((cp) => cp.id === item.id).map((product) => (
                                                                                                            <div className="d-flex justify-content-center align-items-center p-1" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>
                                                                                                                <div className="top-food-add-min-btn"
                                                                                                                    onClick={() => {
                                                                                                                        item.quantity = 1;
                                                                                                                        removeProduct(item);
                                                                                                                        this.forceStateUpdate();
                                                                                                                    }}  >
                                                                                                                    <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                                </div>
                                                                                                                <div className="top-food-count ms-1 me-1">
                                                                                                                    {product.quantity}
                                                                                                                </div>
                                                                                                                {item?.store?.store_category_id == 1 ? (
                                                                                                                    <>
                                                                                                                        <div className="top-food-add-min-btn"
                                                                                                                            onClick={() => {
                                                                                                                                item.quantity = 1;
                                                                                                                                addProduct(item);
                                                                                                                                this.handleFreeItemPopUp();

                                                                                                                                this.forceStateUpdate();
                                                                                                                            }}  >
                                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {product.stock > product.quantity ? (
                                                                                                                            <div className="top-food-add-min-btn"
                                                                                                                                onClick={() => {
                                                                                                                                    item.quantity = 1;
                                                                                                                                    addProduct(item);
                                                                                                                                    // this.handleFreeItemPopUp();

                                                                                                                                    this.forceStateUpdate();
                                                                                                                                }}  >
                                                                                                                                <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                            </div>
                                                                                                                        ) : (
                                                                                                                            <div className="top-food-add-min-btn">
                                                                                                                                <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                                                {/* <Ink duration={500} style={{ color: "#aaa" }} /> */}
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <div className="d-flex justify-content-center pt-2 fs-7 fw-bolder">
                                                                                                        <div className="top-food-btn px-3"
                                                                                                            onClick={() => {
                                                                                                                item.quantity = 1;
                                                                                                                addProduct(item);
                                                                                                                this.handleFreeItemPopUp();

                                                                                                                this.forceStateUpdate();
                                                                                                            }}    >
                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                            <span style={{ color: "green", }}   >
                                                                                                                ADD
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                ) : null}
                                                                            </div>
                                                                            : (item?.store?.storecategory?.id == 1 ? 1 == 1 : item.stock > 0) ? (
                                                                                <>
                                                                                    {item?.is_combo && item?.combo_categories?.length > 0 ? (
                                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                                            <ComboCustomization
                                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                                product={item}
                                                                                                addProduct={addProduct}
                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                storeBtn={true} />
                                                                                        </div>
                                                                                    ) : item?.addoncategories?.length > 0 ? (
                                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                                            <Customization
                                                                                                floatCart={(e) => this.floatCart(e)}
                                                                                                product={item}
                                                                                                addProduct={addProduct}
                                                                                                forceUpdate={this.forceStateUpdate}
                                                                                                storeBtn={true}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="position-absolute" style={{ right: '35px' }}>
                                                                                            {cartProducts?.find((cp) => cp.id === item?.id) !== undefined ? (
                                                                                                <>
                                                                                                    {cartProducts?.filter((cp) => cp.id === item?.id)?.map((product) => (
                                                                                                        <div className="d-flex justify-content-right align-items-center text-end mt-2" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>

                                                                                                            <div className="top-food-add-min-btn"
                                                                                                                onClick={() => {
                                                                                                                    item.quantity = 1;
                                                                                                                    removeProduct(item);
                                                                                                                    this.forceStateUpdate();
                                                                                                                }}  >
                                                                                                                <AiOutlineMinus style={{ color: "green", }} size={14} />
                                                                                                            </div>
                                                                                                            <div className="top-food-count ms-1 me-1">
                                                                                                                {product.quantity}
                                                                                                            </div>
                                                                                                            {item?.store?.store_category_id == 1 ? (
                                                                                                                <div className="top-food-add-min-btn"
                                                                                                                    onClick={() => {
                                                                                                                        item.quantity = 1;
                                                                                                                        addProduct(item);
                                                                                                                        this.handleFreeItemPopUp();
                                                                                                                        this.forceStateUpdate();
                                                                                                                    }}  >
                                                                                                                    <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {product.stock > product.quantity ? (
                                                                                                                        <div className="top-food-add-min-btn"
                                                                                                                            onClick={() => {
                                                                                                                                item.quantity = 1;
                                                                                                                                addProduct(item);
                                                                                                                                this.handleFreeItemPopUp();
                                                                                                                                this.forceStateUpdate();
                                                                                                                            }}  >
                                                                                                                            <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <div className="top-food-add-min-btn">
                                                                                                                            <AiOutlineStop style={{ color: "green", }} size={16} />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                            ) : (
                                                                                                <div className="d-flex justify-content-end pt-2 fs-7 fw-bolder" >
                                                                                                    <div className="top-food-btn px-3"
                                                                                                        onClick={() => {
                                                                                                            item.quantity = 1;
                                                                                                            addProduct(item);
                                                                                                            this.handleFreeItemPopUp();
                                                                                                            this.forceStateUpdate();
                                                                                                        }}    >
                                                                                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                                                                                        <span style={{ color: "green", }}   >
                                                                                                            ADD
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )
                                                                                    }
                                                                                </>
                                                                            ) : (
                                                                                <div className="d-flex align-items-center pt-2 ms-3 me-2 mt-1">
                                                                                    <div className="text-danger">
                                                                                        Out Of Stock
                                                                                    </div>
                                                                                    {/* <Ink duration={500} style={{ color: "#aaa" }} /> */}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                {item?.coupons?.length > 0 ?
                                                                    <div className="w-100 position-absolute bottom-0 pt-3 px-3 fw-500 text-center mt-1" style={{ borderRadius: '0px 0px 15px 15px', backgroundColor: '#3e996f', color: '#fff', zIndex: '5' }}>
                                                                        {item?.coupons[0]?.discount_type === "PERCENTAGE" ?
                                                                            <span>
                                                                                {item?.coupons[0]?.coupon_discount}%
                                                                                <>
                                                                                    {item?.coupons[0]?.min_sub_total > 0 &&
                                                                                        <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                                    }
                                                                                </> OFF USE :
                                                                                <span className="fw-800"> {item?.coupons[0]?.code} </span>
                                                                            </span>
                                                                            : item?.coupons[0]?.discount_type === "FIXED" ? (
                                                                                <span>
                                                                                    RS.{item?.coupons[0]?.coupon_discount}
                                                                                    <>
                                                                                        {item?.coupons[0]?.min_sub_total > 0 &&
                                                                                            <span className="fw-800"> RS {item?.coupons[0]?.min_sub_total} </span>

                                                                                        }
                                                                                    </> OFF USE

                                                                                    <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                                </span>
                                                                            ) : <span>
                                                                                RS.{item?.coupons[0]?.coupon_discount} OFF USE <span className="fw-800">{item?.coupons[0]?.code} </span>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    {/* no items image */}
                                    {categoryitems?.length > 0 ? (
                                        ""
                                    ) : (
                                        <div
                                            className="pt-3 d-flex flex-column align-items-center"
                                            style={{ paddingBottom: "20vw" }}
                                        >
                                            <div className="mt-3 d-flex flex-column align-items-center">
                                                <img
                                                    src={image}
                                                    alt="no items"
                                                    style={{
                                                        objectFit: "cover",
                                                        width: "70%",
                                                    }}
                                                />
                                            </div>
                                            <span className="mt-4 fs-7">
                                                Sorry...No items Found !!!
                                            </span>
                                        </div>
                                    )}
                                </div>

                                {/* <PopularItems count={this.state.count} store={store} /> */}
                                {/* <Items count={this.state.count} store={store} /> */}

                                {/* end button */}
                                <div className={`mt-5 w-100 position-fixed px-3 py-2 ${!store?.store?.is_active && 'store-not-active-click'}`}
                                    style={{ bottom: "0", backgroundColor: "#fff", zIndex: '999' }}  >
                                    <button
                                        onClick={() => this.openMenu()}
                                        className="btn w-100 py-3"
                                        style={{
                                            color: "#FEFEFF",
                                            fontWeight: "700",
                                            fontSize: "14px",
                                            background:
                                                "linear-gradient(98.81deg, #FEA24B -0.82%, #FD8814 101.53%)",
                                            borderRadius: "15px",
                                        }}
                                    >
                                        Browse Menu
                                    </button>
                                </div>
                                {!store?.store?.is_active ? (
                                    <div style={{ "position": "fixed", "bottom": "12px", "textAlign": "center", "width": "100%", "backgroundColor": "#000", "color": "white", "padding": "0.8rem", "zIndex": "2147483647" }}>
                                        <div className="error-shake">
                                            Currently not accepting any orders
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                        )}

                        {/* category bottomsheet */}
                        <BottomSheet
                            open={this.state.cart}
                            onDismiss={() => this.closeMenu()}
                            snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                        >
                            <div className="px-2">
                                <div className="store-filter-section px-3 d-flex flex-column align-items-center ">
                                    <div className="store-bottom-menu w-100" onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                        this.setState({ cart: false })
                                    }}
                                    >
                                        ALL
                                    </div>
                                    {store?.store?.itemcategories?.map((category) => (
                                        <div key={category?.id} className={this.state?.category_id === category.id ? "store-bottom-menu w-100 text-black border-dark" : "store-bottom-menu w-100"}
                                            onClick={() => this.goToViolation(category.id)}   >
                                            {category.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </BottomSheet>

                        {/* coupon bottomsheet */}
                        <BottomSheet
                            open={this.state.couponsheet}
                            onDismiss={() => this.closeCoupon()}
                            snapPoints={({ maxHeight }) => 0.4 * maxHeight}
                        >
                            <div className="px-2">
                                <div className="mt-3 pt-1 px-2 d-flex justify-content-between">
                                    <button className="btn store-cpn-btn px-3 py-2">
                                        <CopyToClipboard
                                            text={this.state?.coupon?.code}
                                            onCopy={() =>
                                                this.setState({ copied: true, copied_code: this.state?.coupon?.code, })}>
                                            <div className="" style={{ fontWeight: "680" }}>
                                                <MdOutlineContentCopy size={"1.2em"} style={{ color: "#FFF" }} />
                                                <span className="text-white ps-2">
                                                    {this?.state?.coupon?.code}{" "}
                                                </span>
                                            </div>
                                        </CopyToClipboard>
                                    </button>
                                    {this.state.copied && this.state.copied_code == this.state?.coupon?.code ? (
                                        <div className="me-3 py-1 pe-3">
                                            <MdDone size={"1.5em"} style={{ color: "green" }} />
                                            <span style={{ color: "green", fontsize: "14px", }}>
                                                Copied
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                <hr></hr>
                                <div className="px-2">
                                    <div className="text-dark fs-4 text-bold">
                                        {this.state?.coupon?.name}
                                    </div>
                                    <div className="mt-1 ps-2">
                                        {this.state?.coupon?.description}
                                    </div>
                                </div>
                            </div>
                        </BottomSheet>
                        {/* share bottomsheet */}
                        {/* <BottomSheet
                        open={this.state.sharesheet}
                        onDismiss={() => this.closeShare()}
                        snapPoints={({ maxHeight }) => 0.4 * maxHeight}
                    >
                        <div className="px-1">
                            <RWebShare
                                data={{
                                    url: ITEM_SHARE_URL + '/single-product/' + this.state.share?.id,
                                    title: this.state.share?.name + " " + this.state.share?.description
                                }}



                            />
                        </div>
                    </BottomSheet> */}
                        {this.state.sharesheet ? ""
                            :
                            <FloatCart float={this.state.floatCart} isRunning={this.state.isRunning} browseOn={true} />
                        }
                    </React.Fragment>
                )
                }
                {
                    this.state.noCategory &&
                    <div className="position-fixed start-50 translate-middle" style={{ zIndex: "9999999999", bottom: '11rem' }}>
                        <span style={{ color: '#fff', padding: '10px 20px', backgroundColor: "black", borderRadius: '8px' }}>
                            no items in this category
                        </span>
                    </div>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    store: state.singleStore.store,
    user: state.user.user,
    storeInfo: state.cart.store_info,
    cartProducts: state.cart.products,
});

export default connect(mapStateToProps, {
    getSingleStore,
    toggleFavItem,
    addProduct,
    removeProduct,
    updateStore,
})(Store);

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import debounce from "lodash.debounce";
import MedicalStoreCard from "./components/MedicalStoreCard.js";
import { useLazyGetAllMedicalStoresQuery } from "../../../../redux/mobile/home/homeApi.js";
import { BiLoader } from "react-icons/bi";

const tabs = [
    { id: "all", value: "All" },
    { id: "rating", value: "Rating" },
    { id: "time", value: "Time" },
    { id: "buy_get", value: "Buy 1 Get 1" },
    { id: "free_delivery", value: "Free Delivery" },
    { id: "free_item", value: "Free Item" },
];

const AllMedicalStore = () => {
    const [getAllMedicalStores, { data: allStores, isLoading, isFetching }] =
        useLazyGetAllMedicalStoresQuery();

    const latitude = JSON.parse(
        localStorage?.getItem("userSetAddress")
    )?.latitude;
    const longitude = JSON.parse(
        localStorage?.getItem("userSetAddress")
    )?.longitude;

    const [page, setPage] = useState(1);
    const pageSize = 5;
    const [selectedTab, setSelectedTab] = useState("all");
    const [availableStores, setAvailableStores] = useState([]); // State to store concatenated results

    const getFilters = (tabId) => {
        let filters = {};
        switch (tabId) {
            case "rating":
                filters.sortBy = "rating";
                break;
            case "time":
                filters.sortBy = "preparation_time";
                break;
            case "buy_get":
                filters.hasBuyGetOffer = true;
                break;
            case "free_delivery":
                filters.isFreeDelivery = true;
                break;
            case "free_item":
                filters.hasFreeItem = true;
                break;
            default:
                break;
        }
        return filters;
    };

    const handleTabClick = (tabId) => {
        setSelectedTab(tabId);
        setPage(1); // Reset to the first page
        setAvailableStores([]); // Clear the list for new tab
    };

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const response = await getAllMedicalStores({
                    latitude,
                    longitude,
                    page,
                    pageSize,
                    ...getFilters(selectedTab),
                }).unwrap();

                setAvailableStores((prev) =>
                    page === 1
                        ? response.allStores
                        : [...prev, ...response.allStores]
                );
            } catch (err) {
                console.error("Failed to fetch stores:", err);
            }
        };

        const isEnd =
            allStores?.pagination?.total &&
            page > Math.ceil(allStores?.pagination?.total / pageSize);

        if (!isEnd) fetchStores();
    }, [page, selectedTab, getAllMedicalStores]);

    const handleScroll = useCallback(
        debounce(() => {
            const { scrollHeight, scrollTop, clientHeight } =
                document.documentElement;
            if (
                scrollHeight - scrollTop <= clientHeight * 1.3 &&
                !isLoading &&
                !isFetching
            ) {
                setPage((prev) => prev + 1);
            }
        }, 200),
        [isLoading, isFetching]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    const getStoreCouponText = (store) => {
        const storeCoupon = store?.coupons?.find(
            (coupon) => coupon?.coupon_type === "STORE"
        );

        return (
            <div
                className="d-flex flex-column justify-content-center position-absolute w-100 text-center"
                style={{
                    borderRadius: "0px 0px 20px 20px",
                    bottom: "0px",
                    lineHeight: "1.2",
                    background: "linear-gradient(0deg,black, transparent )",
                }}
            >
                {store?.is_free_item ? (
                    <span className="all-restuarant-for-you-coupon1 px-2 ms-1 pb-1 text-center">
                        FREE ITEM
                    </span>
                ) : storeCoupon ? (
                    storeCoupon?.discount_type === "PERCENTAGE" ? (
                        <>
                            <span className="all-restuarant-for-you-coupon1 px-2 ms-1 mt-2">
                                {store?.coupons[0]?.coupon_discount}% OFF
                            </span>
                            {store?.coupons[0]?.min_sub_total > 0 && (
                                <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                    ABOVE ₹ {store?.coupons[0]?.min_sub_total}
                                </span>
                            )}
                            {store?.coupons[0]?.min_sub_total < 0 &&
                                store?.coupons[0]?.max_discount > 0 && (
                                    <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                        UPTO ₹ {store?.coupons[0]?.max_discount}
                                    </span>
                                )}
                        </>
                    ) : (
                        <>
                            <span className="all-restuarant-for-you-coupon1 px-2 ms-1 mt-2">
                                ₹{store?.coupons[0]?.coupon_discount} OFF
                            </span>
                            {store?.coupons[0]?.min_sub_total > 0 && (
                                <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                    ABOVE ₹ {store?.coupons[0]?.min_sub_total}
                                </span>
                            )}
                            {store?.coupons[0]?.min_sub_total < 0 &&
                                store?.coupons[0]?.max_discount > 0 && (
                                    <span className="all-restuarant-for-you-coupon2 px-2 ms-1">
                                        UPTO ₹ {store?.coupons[0]?.max_discount}
                                    </span>
                                )}
                        </>
                    )
                ) : store.coupons && store.coupons.length > 0 ? (
                    <span
                        className="all-restuarant-for-you-coupon1 px-2 ms-1 pb-2 pt-1 text-center"
                        style={{ fontSize: "13px" }}
                    >
                        ITEM COUPON
                    </span>
                ) : null}
            </div>
        );
    };

    // Calculate Rating Count
    const ratingCount = (ratings) => {
        if (!ratings || ratings.length === 0) return 0;
        let length = ratings.length;
        let total = ratings.reduce((acc, rating) => acc + rating.rating, 0);
        let average = total / (length || 1);
        return Math.round(average);
    };

    return (
        <>
            <div>
                <div className="px-3 mt-3 d-flex align-items-center">
                    <GiHamburger size={20} style={{ color: "#FF9A62" }} />
                    <span className="home-heading ms-2">
                        All Stores For You
                    </span>
                </div>
                <div className="d-flex flex-column px-3 mt-3">
                    <div className="d-flex flex-row align-items-center">
                        <div
                            className="filter-menu hidden-scroll-bar text-center gap-2 pb-3"
                            style={{ gridTemplateColumns: `repeat(7,1fr)` }}
                        >
                            {tabs.map((tab) => (
                                <div
                                    key={tab.id}
                                    className={
                                        tab.id === selectedTab
                                            ? "py-1 filter-btn-active"
                                            : "py-1 filter-btn"
                                    }
                                    onClick={() => handleTabClick(tab.id)}
                                >
                                    {tab.value}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ transition: "all 0.5s ease" }}>
                    {availableStores.length > 0 ? (
                        availableStores.map((store) => (
                            <MedicalStoreCard
                                store={store}
                                key={store.id}
                                getStoreCouponText={getStoreCouponText}
                                ratingCount={ratingCount}
                            />
                        ))
                    ) : (
                        <>
                            {isLoading || isFetching ? null : (
                                <>
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ height: "100px" }}
                                    >
                                        <span className="no-data">
                                            No Stores Available
                                        </span>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {(isLoading || isFetching) && (
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "20px",
                            }}
                        >
                            <BiLoader
                                className="loader"
                                style={{ height: "50px", width: "50px" }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AllMedicalStore;


import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { MdCardGiftcard } from "react-icons/md";
import Truncate from "react-truncate";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../../api";
import {  GiScooter } from "react-icons/gi";


const ResturantCard = ({store, getStoreCouponText, ratingCount}) => {
  return (
    <>
        <Link
                key={store.id}
                to={`/single-store/${store.id}`}
                onClick={() => localStorage.setItem("scrollPosition", window.scrollY)}
              >
                <div className="px-3 mt-3 d-flex align-items-center">
                  {/* Store Image and Overlay */}
                  <div
                    className={`position-relative ${
                      !store?.is_active && "store-not-active"
                    }`}
                  >
                    <ProgressiveImage
                      delay={20}
                      src={`${IMAGE_BASE_URL}${store?.image}`}
                      placeholder={PLACE_HOLDER_IMAGE}
                    >
                      {(src) => (
                        <img
                          src={src}
                          style={{
                            width: "30vw",
                            height: "33vw",
                            borderRadius: "20px",
                            objectFit: "cover",
                            backgroundRepeat: "round",
                          }}
                          alt={store.name}
                        />
                      )}
                    </ProgressiveImage>

                    {/* Overlay */}
                    <div
                      className="position-absolute"
                      style={{
                        top: 0,
                        width: "30vw",
                        height: "33vw",
                        borderRadius: "20px",
                      }}
                    ></div>

                    {/* Buy Text Ribbon */}
                    {store.is_buy_text && (
                      <div className="ribbon-buy">
                        <span>{store.is_buy_text}</span>
                      </div>
                    )}

                    {/* Coupon Text */}
                    {getStoreCouponText(store)}
                  </div>

                  {/* Store Details */}
                  <div className="flex-grow-1 ms-3 pe-5 mt-1">
                    {/* Scratch Card */}
                    {store.has_scratch === 1 && (
                      <p className="pb-0 mb-0">
                        <MdCardGiftcard /> Scratch Card
                      </p>
                    )}

                    {/* Store Name */}
                    <h5
                      className="fs-6 m-0"
                      style={{ color: "#09051C", fontWeight: "600" }}
                    >
                      {store.name}
                    </h5>

                    {/* Ratings and Time */}
                    <div
                      className={`d-flex align-items-center ${
                        !store?.is_active && "store-not-active"
                      }`}
                    >
                      {store?.ratings?.length > 0 ? (
                        <>
                          <span className="m all-resturant-rating">
                          {ratingCount(store.ratings)}
                          </span>
                          <AiFillStar
                            className="me-2"
                            style={{ color: "#FF9A62" }}
                            size={11}
                          />
                        </>
                      ) : (
                        <>
                          <span className="m all-resturant-rating">
                            {store?.rating}
                          </span>
                          <AiFillStar
                            className="me-2"
                            style={{ color: "#FF9A62" }}
                            size={11}
                          />
                        </>
                      )}
                      <span
                        className="me-2"
                        style={{
                          fontSize: "13px",
                          fontWeight: "650",
                          color: "#3B3B3B",
                        }}
                      >
                        {store?.preparation_time
                          ? `${store.preparation_time} Min - `
                          : ""}{" "}
                        {store?.delivery_radius ? `${store.delivery_radius} Km` : ""}
                      </span>
                    </div>

                    {/* Description */}
                    <div
                      className="resturant-description"
                      style={{ color: "#b5b5b5", fontSize: "14px" }}
                    >
                      <Truncate lines={2}>{store?.description}</Truncate>
                    </div>

                    {/* Free Delivery */}
                    {store?.is_freedelivery === 1 && store?.is_active === 1 && (
                      <div className="d-flex align-items-center resturant-description-top">
                        <GiScooter style={{ color: "#FF9A62" }} size={20} />
                        <span className="all-resturant-delivery ms-2 mt-1">
                          Free Delivery Upto...
                        </span>
                      </div>
                    )}

                    {/* Combos Delivery */}
                    {store?.combo_categories?.combos && store?.is_active && (
                      <div className="d-flex align-items-center resturant-description-top">
                        <GiScooter style={{ color: "#FF9A62" }} size={20} />
                        <span className="all-resturant-delivery ms-2 mt-1">
                          Free Delivery Upto...
                        </span>
                      </div>
                    )}

                    {/* Store Closed */}
                    {!store?.is_active && (
                      <div className="text-danger">Store Closed</div>
                    )}
                  </div>
                </div>
              </Link>
    </>
  )
}

export default ResturantCard

import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from "../../Elements/MobileBackButton";
import { formatPrice } from "../../Helpers/formatPrice";
import editlocation from "../../../assets/images/location.png";
// import cod from "../../../assets/images/cod.png";
import ContentLoader from "react-content-loader";
import cod from "../../../assets/images/cash-on-delivery 1.png";
import walletImg from "../../../assets/images/entypo_wallet.png";
import upi from "../../../assets/images/UPI1.jpg";
import mscard1 from "../../../assets/images/Mastercard_logo 1.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Link } from "react-router-dom";
import {
    getDeliverySlab,
    updateSlabDetails,
    clearCart,
    clearPrescriptionImage,
    calcualteStoreMaxDistance,
} from "../../../redux/mobile/cart/action";
import {
    placeOrder,
    getNearStores,
} from "../../../redux/mobile/checkOut/action";
import { updateUserReduxInfo } from "../../../redux/mobile/user/action";
import { IoIosArrowDropright } from "react-icons/io";
import {
    ORDER_PROCESS_STATUS_UPDATE_RAZORPAY_URL,
    PAYTM_PAYMENT_URL,
    RAZORPAY_ORDER_URL,
} from "../../../api";
import Loading from "../../Elements/loading";
import LoadingTwo from "../../Elements/loadingTwo";
import Tip from "./Tip";
import Shake from "react-reveal/Shake";
import CancelOrder from "./CancelOrder";
import moment from "moment";
import axios from "axios";

export class ConfirmOrder extends Component {
    state = {
        loading: false,
        loadingTwo: false,
        payment_sheet: false,
        is_schedule: false,
        delivery_type: false,
        payment_type: "",
        address: JSON.parse(localStorage.getItem("userSetAddress")),
        canPayPartialWithWallet: false,
        canPayFullWithWallet: false,
        paymentSelected: false,
        distance: 0,
        delivery_charges: 0,
        walletAmount: 0,
        couponAppliedAmount: 0,
        totalTax: 0,
        surge_fee: 0,
        tip: "",
        custom_tip: "",
        nearstores: [],
        scheduledDate: "",
        scheduledTime: "",
        delivery_sheet: false,
        store: "",
        max_distance: "",
        payment_methods: [],
    };

    componentDidMount() {
        this.fetchPaymentGateways();
        const { user, cartProducts } = this.props;
        let tip = localStorage.getItem("tip");
        this.setState({ loading: true, tip: tip });
        if (!cartProducts || !cartProducts.length > 0) {
            window.location.replace("/cart");
        }
        this.props.updateUserReduxInfo(user.data.auth_token);
        if (
            this.props.maxDistanceStore &&
            this.props.maxDistanceStore.store_id &&
            this.props.maxDistanceStore.store_id.city
        ) {
            this.props
                .getDeliverySlab(
                    this.props.maxDistanceStore.store_id.city.id,
                    this.props.maxDistanceStore.distance
                )
                .then((response) => {
                    // this.checkDeliverySlab();
                });
        }

        this.props
            .getNearStores(
                JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
                JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude,
                cartProducts[0]?.store?.id
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        nearstores: response.payload.nearstores,
                        loading: false,
                    });
                }
            });

        this.props
            .calcualteStoreMaxDistance(
                user.success && user.data.id,
                cartProducts,
                JSON.parse(localStorage.getItem("userSetAddress"))
            )
            .then((response) => {
                if (response && response.payload) {
                    this.setState({
                        max_distance: response.payload.distance,
                        store: response.payload.store_id,
                    });

                    localStorage.setItem(
                        "max_distance",
                        response.payload.distance
                    );
                }
            });
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);
    }

    fetchPaymentGateways = async () => {
        try {
            const paymentGateways = await axios.get(
                "https://grosavbeta.howincloud.com/public/api/get-payment-getaways"
            );
            console.log(
                "Payment Gateways Response:",
                paymentGateways?.data?.data
            );

            if (paymentGateways.data.success) {
                // return paymentGateways.data.data;
                this.setState({ payment_methods: paymentGateways.data.data });
            } else {
                throw new Error("Failed to fetch payment gateways.");
            }
        } catch (error) {
            console.error("Error fetching payment gateways:", error);
            throw error; // Rethrow error for handling by the caller
        }
    };

    componentWillReceiveProps(nextProps) {
        if (
            nextProps?.user?.data?.is_active_plan == 1 &&
            nextProps?.user?.data?.remainining_plan_days > 0
        ) {
            this.setState({ delivery_charges: 0 });
        } else if (
            nextProps.store_info?.is_freedelivery &&
            parseFloat(this.state?.max_distance) <=
                parseFloat(
                    nextProps.store_info?.free_base_delivery_distance_within
                )
        ) {
            this.calculateDynamicCityDeliveryCharge(nextProps?.total);
        } else if (
            nextProps.store_info?.is_freedelivery &&
            parseFloat(nextProps?.total) >=
                parseFloat(
                    nextProps.store_info?.free_delivery_on_above_subtotal
                )
        ) {
            this.calculateDynamicCityDeliveryCharge(nextProps?.total);
        } else {
            if (
                nextProps.store_info?.delivery_charge_type === "DYNAMIC" &&
                nextProps.store_info?.base_delivery_distance &&
                nextProps.store_info?.extra_delivery_distance &&
                nextProps.store_info?.extra_delivery_distance &&
                nextProps.store_info?.base_delivery_charge
            ) {
                this.setState({ distance: nextProps.distance }, () => {
                    this.calculateDynamicDeliveryCharge();
                });
            } else if (
                nextProps.store_info?.delivery_charge_type === "FIXED" &&
                parseFloat(nextProps.store_info?.delivery_charge) > 0
            ) {
                this.setState({
                    delivery_charges: nextProps.store_info?.delivery_charge,
                });
                localStorage.setItem(
                    "delivery_charges",
                    nextProps.store_info?.delivery_charge
                );
            } else if (
                nextProps.store_info?.city?.delivery_charge_type ===
                    "DYNAMIC" &&
                nextProps.store_info?.city?.base_delivery_distance &&
                nextProps.store_info?.city?.extra_delivery_distance &&
                nextProps.store_info?.city?.extra_delivery_distance &&
                nextProps.store_info?.city?.base_delivery_charge
            ) {
                this.setState({ distance: nextProps.distance }, () => {
                    //check if restaurant has dynamic delivery charge..
                    this.calculateDynamicCityDeliveryCharge(nextProps?.total);
                });
            } else if (
                nextProps.store_info?.city?.delivery_charge_type === "FIXED" &&
                parseFloat(nextProps.store_info?.city?.delivery_charge) > 0
            ) {
                this.setState({
                    delivery_charges:
                        nextProps.store_info?.city?.delivery_charge,
                });
                localStorage.setItem(
                    "delivery_charges",
                    nextProps.store_info?.city?.delivery_charge
                );
            }
        }

        if (
            nextProps &&
            nextProps.maxDistanceStore &&
            nextProps.maxDistanceStore?.store_id
        ) {
            if (nextProps.maxDistanceStore?.store_id?.city?.is_surge === 1) {
                this.setState({
                    surge_fee:
                        nextProps.maxDistanceStore?.store_id?.city?.surge_fee,
                });
            }
        }
    }
    calculateDynamicDeliveryCharge = () => {
        const { maxDistanceStore } = this.props;
        //this.setState({ delivery_charges: 0 })
        const distanceFromUserToStore = maxDistanceStore.distance;
        if (
            distanceFromUserToStore >
            maxDistanceStore.store_id?.base_delivery_distance
        ) {
            const extraDistance =
                distanceFromUserToStore -
                maxDistanceStore.store_id?.base_delivery_distance;
            const extraCharge =
                (extraDistance /
                    maxDistanceStore.store_id?.extra_delivery_distance) *
                maxDistanceStore.store_id?.extra_delivery_charge;
            //let dynamicDeliveryCharge
            let dynamicDeliveryCharge =
                parseFloat(maxDistanceStore.store_id?.base_delivery_charge) +
                parseFloat(extraCharge);
            // if (maxDistanceStore.store_id?.is_freedelivery) {
            //     let changeByOne = parseFloat(maxDistanceStore.store_id?.base_delivery_charge / maxDistanceStore.store_id?.free_base_delivery_distance_within)
            //     dynamicDeliveryCharge = changeByOne + parseFloat(extraCharge);
            // } else {
            //     dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);
            // }
            dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
            // alert(dynamicDeliveryCharge)
            this.setState({ delivery_charges: dynamicDeliveryCharge });
            localStorage.setItem("delivery_charges", dynamicDeliveryCharge);
        } else {
            this.setState({
                delivery_charges:
                    maxDistanceStore.store_id?.base_delivery_charge,
            });
            localStorage.setItem(
                "delivery_charges",
                maxDistanceStore.store_id?.base_delivery_charge
            );
        }
        setTimeout(() => {}, 500);
    };

    calculateDynamicCityDeliveryCharge = (total) => {
        const { maxDistanceStore, store_info } = this.props;

        const distanceFromUserToStore = maxDistanceStore.distance;
        let city_free_delivery = false;

        if (maxDistanceStore.store_id?.is_freedelivery) {
            let newDeliveryCharge = 0;

            if (
                total >= store_info?.free_delivery_on_above_subtotal &&
                store_info?.free_delivery_on_above_subtotal !== "0"
            ) {
                if (
                    maxDistanceStore.store_id?.is_freedelivery &&
                    distanceFromUserToStore <
                        maxDistanceStore.store_id
                            ?.free_base_delivery_distance_within
                ) {
                    city_free_delivery = true;
                    this.setState({ delivery_charges: newDeliveryCharge });
                    localStorage.setItem("delivery_charges", newDeliveryCharge);
                    // alert("city_free_delivery")
                } else if (
                    maxDistanceStore.store_id?.is_freedelivery &&
                    distanceFromUserToStore >
                        maxDistanceStore.store_id
                            ?.free_base_delivery_distance_within
                ) {
                    if (
                        store_info?.free_delivery_on_above_subtotal ===
                            "0.00" ||
                        store_info?.free_base_delivery_distance_within ===
                            "0.00"
                    ) {
                        city_free_delivery = true;
                    } else {
                        newDeliveryCharge = store_info?.city?.delivery_charge;
                        city_free_delivery = false;
                    }
                } else {
                    city_free_delivery = true;
                }
            } else {
                // newDeliveryCharge = store_info?.city?.delivery_charge;
                city_free_delivery = false;
                //this.calculateDynamicDeliveryCharge()
            }

            //this.setState({ delivery_charges: newDeliveryCharge });
            // this.forceStateUpdate();
        }

        if (city_free_delivery === false) {
            //this.calculateDynamicDeliveryCharge()
            if (
                distanceFromUserToStore >
                maxDistanceStore.store_id?.base_delivery_distance
            ) {
                const extraDistance =
                    distanceFromUserToStore -
                    maxDistanceStore.store_id?.base_delivery_distance;
                const extraCharge =
                    (extraDistance /
                        maxDistanceStore.store_id?.extra_delivery_distance) *
                    maxDistanceStore.store_id?.extra_delivery_charge;
                let dynamicDeliveryCharge = 0;
                let changeByOne;
                if (
                    store_info?.is_freedelivery &&
                    store_info?.free_delivery_on_above_subtotal !== "0" &&
                    total >= store_info?.free_delivery_on_above_subtotal &&
                    distanceFromUserToStore <=
                        store_info?.free_base_delivery_distance_within
                ) {
                    if (extraDistance <= 0) {
                        changeByOne = 0;
                    } else {
                        changeByOne =
                            (parseFloat(
                                maxDistanceStore.store_id?.base_delivery_charge
                            ) *
                                parseFloat(extraDistance)) /
                            parseFloat(
                                maxDistanceStore.store_id
                                    ?.base_delivery_distance
                            );
                    }
                    dynamicDeliveryCharge = changeByOne;
                    // ////// console.log("dynamicDeliveryCharge : ", changeByOne, parseFloat(maxDistanceStore.store_id?.base_delivery_charge), parseFloat(extraDistance), parseFloat(maxDistanceStore.store_id?.base_delivery_distance), distanceFromUserToStore);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);

                    this.setState({ delivery_charges: dynamicDeliveryCharge });
                    localStorage.setItem(
                        "delivery_charges",
                        dynamicDeliveryCharge
                    );
                } else if (
                    store_info?.is_freedelivery &&
                    store_info?.free_base_delivery_distance_within !== "0" &&
                    distanceFromUserToStore >=
                        store_info?.free_base_delivery_distance_within
                ) {
                    if (extraDistance <= 0) {
                        changeByOne = 0;
                    } else {
                        changeByOne =
                            (parseFloat(
                                maxDistanceStore.store_id?.base_delivery_charge
                            ) *
                                parseFloat(extraDistance)) /
                            parseFloat(
                                maxDistanceStore.store_id
                                    ?.base_delivery_distance
                            );
                    }
                    dynamicDeliveryCharge = changeByOne;
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);

                    this.setState({ delivery_charges: dynamicDeliveryCharge });
                    localStorage.setItem(
                        "delivery_charges",
                        dynamicDeliveryCharge
                    );
                } else {
                    ////// console.log("here...1");

                    //dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);
                    this.calculateDynamicDeliveryCharge();
                }
            } else {
                // this.setState({
                //     delivery_charges: maxDistanceStore.store_id?.base_delivery_charge,
                // });

                this.calculateDynamicDeliveryCharge();
            }
        }
    };
    getTotalSavings = () => {
        const { cartProducts } = this.props;
        let itemSaving = 0;
        let totalsaving = 0;
        cartProducts.forEach((item) => {
            if (item.market_price && item.market_price > 0) {
                itemSaving +=
                    (item.market_price - item.selling_price) * item.quantity;
            }
        });
        totalsaving =
            parseFloat(itemSaving) +
            parseFloat(formatPrice(this.props.coupon.finalDiscount));
        return formatPrice(totalsaving);
    };

    getTotalAfterCalculation = () => {
        const {
            cartTotal,
            coupon,
            charges,
            store_info,
            cartProducts,
            user,
            current_slab,
        } = this.props;
        let total = cartTotal.totalPrice;
        let calc = 0;
        let totalTax = 0;
        if (coupon.code) {
            calc = formatPrice(
                parseFloat(total) -
                    (parseFloat(coupon.finalDiscount) || 0.0) +
                    (parseFloat(charges.store_charge) || 0.0)
            );
        } else {
            calc = formatPrice(
                parseFloat(total) + parseFloat(charges.store_charge || 0.0)
            );
        }

        cartProducts.forEach((item) => {
            if (item.store?.is_tax === 1 && item.store?.tax > 0) {
                let total = 0;
                let price = 0;
                if (item?.dealofdays?.length > 0 && item.selling_price > 0) {
                    price =
                        item.selling_price -
                        parseFloat(
                            (item.selling_price *
                                parseFloat(item.dealofdays[0].offer)) /
                                100
                        );
                    total += price * item.quantity;
                } else if (item.selling_price > 0) {
                    total += item.selling_price * item.quantity;
                }
                if (item.is_combo) {
                    if (item.selectedcombos) {
                        item.selectedcombos.forEach((combo) => {
                            total += combo.price * item.quantity;
                        });
                    }
                } else {
                    if (item.selectedaddons) {
                        item.selectedaddons.forEach((addon) => {
                            let price = 0;
                            if (
                                item?.dealofdays?.length > 0 &&
                                addon.price > 0
                            ) {
                                price =
                                    addon.price -
                                    parseFloat(
                                        (addon.price *
                                            parseFloat(
                                                item.dealofdays[0].offer
                                            )) /
                                            100
                                    );
                                total += price * addon.quantity;
                            } else if (addon.price > 0) {
                                total += addon.price * addon.quantity;
                            }
                        });
                    }
                }
                if (item.store?.tax_type === "PERCENTAGE") {
                    totalTax += (total * item.store.tax) / 100;
                } else {
                    totalTax += item.store.tax;
                }
            }
        });

        calc = formatPrice(parseFloat(calc) + parseFloat(totalTax || 0.0));
        if (this.state.delivery_charges && this.state.delivery_charges > 0) {
            calc = formatPrice(
                parseFloat(calc) +
                    parseFloat(this.state.delivery_charges || 0.0)
            );
        }

        if (this.state.tip && this.state.tip > 0) {
            calc = formatPrice(
                parseFloat(calc) + parseFloat(this.state.tip || 0.0)
            );
        }

        if (store_info.handling_charges > 0) {
            calc = formatPrice(
                parseFloat(calc) +
                    parseFloat(store_info.handling_charges || 0.0)
            );
        }

        if (this.state.surge_fee && this.state.surge_fee > 0) {
            calc = parseFloat(calc) + parseFloat(this.state.surge_fee);
            calc = formatPrice(parseFloat(calc));
        } else {
            if (
                store_info &&
                store_info.city &&
                store_info.city.is_surge === 1 &&
                store_info.city.surge_fee > 0
            ) {
                calc = formatPrice(
                    parseFloat(calc) +
                        parseFloat(store_info.city.surge_fee || 0.0)
                );
            }
        }

        return Math.round(calc);
    };
    checkDeliverySlab = () => {
        const { slabs, cartTotal } = this.props;
        // ////// console.log("slab is in progress");
        // ////// console.log(total)
        // var all_slabs = J
        if (slabs && slabs.success && slabs.sub_slabs) {
            var index = slabs.sub_slabs.findIndex(function (element) {
                return (
                    element.bucket_price_from <= cartTotal.totalPrice &&
                    element.bucket_price_to > cartTotal.totalPrice
                );
            });
            // ////// console.log(
            //     slabs.sub_slabs[index + 1],
            //     slabs.sub_slabs[index],
            //     cartTotal
            // );

            var current_slab = slabs.sub_slabs[index];
            var next_slab = slabs.sub_slabs[index + 1];
            var length = slabs.sub_slabs.length;
            if (
                cartTotal &&
                cartTotal.totalPrice >
                    slabs.sub_slabs[length - 1].bucket_price_to
            ) {
                current_slab = slabs.sub_slabs[length - 1];
                next_slab = undefined;
            }
            //   ////// console.log(slab_details);
            this.props.updateSlabDetails(current_slab, next_slab);
        } else {
            current_slab = undefined;
            next_slab = undefined;
            this.props.updateSlabDetails(current_slab, next_slab);
        }
    };

    StoresTax = () => {
        const { cartProducts, charges } = this.props;
        let totalTax = 0;
        cartProducts.forEach((item) => {
            if (item.store?.is_tax === 1 && item.store?.tax > 0) {
                let total = 0;
                let price = 0;
                if (item?.dealofdays?.length > 0 && item.selling_price > 0) {
                    price =
                        item.selling_price -
                        parseFloat(
                            (item.selling_price *
                                parseFloat(item.dealofdays[0].offer)) /
                                100
                        );
                    total += price * item.quantity;
                } else if (item.selling_price > 0) {
                    total += item.selling_price * item.quantity;
                }
                if (item.is_combo) {
                    if (item.selectedcombos) {
                        item.selectedcombos.forEach((combo) => {
                            total += combo.price * item.quantity;
                        });
                    }
                } else {
                    if (item.selectedaddons) {
                        item.selectedaddons.forEach((addon) => {
                            let price = 0;
                            if (
                                item?.dealofdays?.length > 0 &&
                                addon.price > 0
                            ) {
                                price =
                                    addon.price -
                                    parseFloat(
                                        (addon.price *
                                            parseFloat(
                                                item.dealofdays[0].offer
                                            )) /
                                            100
                                    );
                                total += price * addon.quantity;
                            } else if (addon.price > 0) {
                                total += addon.price * addon.quantity;
                            }
                        });
                    }
                }
                if (item.store?.tax_type === "PERCENTAGE") {
                    totalTax += (total * item.store.tax) / 100;
                } else {
                    totalTax += item.store.tax;
                }
            }
        });
        if (charges.store_charge !== "0.00" || charges.store_charge === null) {
            totalTax = formatPrice(totalTax + charges.store_charge);
        }
        return formatPrice(totalTax);
    };

    checkAndSetAppliedAmount = () => {
        let elem = "";
        elem = "(₹" + localStorage.getItem("couponAppliedAmount") + ")";

        if (this.refs.appliedAmount) {
            this.refs.appliedAmount.innerHTML = elem;
        }
    };

    sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
        axios
            .post(ORDER_PROCESS_STATUS_UPDATE_RAZORPAY_URL, {
                id: id,
                token: this.props.user.data.auth_token,
                payment_id,
                order_id,
                signature,
            })
            .then((response) => {

                console.log("====================================");
                console.log(response?.data?.data?.id,"REEE");
                console.log("====================================");
                if (response?.data?.data?.id) {
                    this.props.history.push(
                        "/order-success/" + response?.data?.data?.id
                    );
                }
            });
    };

    successRazor = () => {
        this.setState({ order_succes: true, order_confirm: false });

        //// console.log('====================================');
        //// console.log("successRazor");
        //// console.log('====================================');
        // setTimeout(() => {
        // 	this.context.router.history.push("/my-orders");
        // }, 5000);
    };

    placeOder = () => {
        const {
            user,
            cartProducts,
            coupon,
            cartTotal,
            current_slab,
            prescriptionImage,
        } = this.props;
        console.log(this.state.max_distance, "kk");
        let is_scheduled = 0;
        let scheduled_date = null;
        let scheduled_time = null;
        // ////// console.log(prescriptionImage)
        if (this.state.paymentSelected) {
            this.setState({ loadingTwo: true });
            if (this.state.delivery_type && this.state.is_schedule) {
                is_scheduled = 1;
                scheduled_date = this.state.scheduledDate;
                scheduled_time = this.state.scheduledTime;
            }

            var tipAmount = this.state.tip;
            var store_id = cartProducts[0]?.store?.id;
            cartProducts.forEach((item) => {
                if (item?.dealofdays?.length > 0) {
                    item.dealoff = parseFloat(item?.dealofdays[0]?.offer);
                } else {
                    item.dealoff = 0;
                }
            });

            this.props
                .placeOrder(
                    user.data.auth_token,
                    cartProducts,
                    store_id,
                    this.state.walletAmount,
                    this.state.canPayPartialWithWallet,
                    coupon.success ? coupon : null,
                    JSON.parse(localStorage.getItem("userSetAddress")),
                    localStorage.getItem("orderComment"),
                    cartTotal,
                    this.state.payment_type,
                    // 1,
                    parseFloat(this.state.distance),
                    tipAmount,
                    is_scheduled,
                    scheduled_date,
                    scheduled_time,
                    0,
                    current_slab,
                    coupon.success ? coupon.code : null,
                    prescriptionImage
                )
                .then((response) => {
                    if (response && response.success) {
                        console.log("====================================");
                        console.log(response.data);
                        console.log("====================================");
                        this.setState({ loading: false });
                        if (response.data.payment_mode === "ONLINE") {
                            // window.location = RAZORPAY_URL + "/" + response.data.id + "/1"; // 1 : Normal Order

                            this.launchRazor(
                                response.data,
                                this.props.history,
                                this.sendOrderCompleteResponse,
                                this.successRazor
                            );
                        } else {
                            localStorage.removeItem("tip");
                            this.props.clearCart();
                            this.props.clearPrescriptionImage();
                            localStorage.removeItem("tip");
                            setTimeout(() => {
                                this.props.history.push(
                                    "/order-success/" + response.data.id
                                );
                            }, 2000);
                        }
                    } else {
                        ////// console.log("API ERROR", response);
                    }
                });
        } else {
            this.setState({ payment_sheet: true });
        }
    };

    launchRazor = (order, history, runFunction, successRazor) => {
        axios
            .post(RAZORPAY_ORDER_URL, {
                amount: parseFloat(order.total).toFixed(2),
                id: order.id,
                token: this.props.user.data.auth_token,
            })
            .then((res) => {
                // //// console.log(res.data.response.id);
                if (res.data.razorpay_success) {
                    if (
                        window.ReactNativeWebView &&
                        localStorage.getItem("activate_razorpay_reactNative") ==
                            "true"
                    ) {
                        var response = {
                            type: "handleRazorPay",
                            options: {
                                key: "rzp_live_KvJRO6e2wr8Yb9",
                                amount:
                                    parseFloat(order.total).toFixed(2) * 100,
                                name: "Grosav",
                                webview_intent: true,
                                currency: "INR",
                                order_id: res.data.response.id,
                                payment_capture: 1,
                                method: {
                                    netbanking: true,
                                    card: true,
                                    wallet: false,
                                    upi: true,
                                },
                                prefill: {
                                    name: this.props.user.data.name,
                                    contact: this.props.user.data.phone,
                                    email: this.props.user.data.email
                                        ? this.props.user.data.email
                                        : "",
                                },
                            },
                            order_id: order.id,
                            auth_token: this.props.user.data.auth_token,
                        };
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify(response)
                        );
                        setTimeout(() => {
                            localStorage.removeItem("tip");
                            this.props.clearCart();
                            this.props.history.push(
                                "/order-success/" + order.id
                            );
                        }, 2000);
                    } else {
                        const options = {
                            key: "rzp_live_KvJRO6e2wr8Yb9",
                            amount: parseFloat(order.total).toFixed(2) * 100,
                            webview_intent: true,
                            name: "Grosav",
                            currency: "INR",
                            order_id: res.data.response.id,
                            payment_capture: 1,
                            method: {
                                netbanking: true,
                                card: true,
                                wallet: false,
                                upi: true,
                            },

                            handler(response) {
                                //// console.log("Final Response", response);
                                runFunction(
                                    order.id,
                                    response.razorpay_payment_id,
                                    response.razorpay_order_id,
                                    response.razorpay_signature
                                );

                                successRazor();
                            },
                            modal: {
                                ondismiss: function () {
                                    //// console.log("closed");
                                    runFunction(order.id, null, null, null);
                                },
                                confirm_close: true,
                            },
                            prefill: {
                                name: this.props.user.data.name,
                                contact: this.props.user.data.phone,
                                email: this.props.user.data.email
                                    ? this.props.user.data.email
                                    : "",
                            },
                        };

                        const rzp1 = new window.Razorpay(options);
                        rzp1.open();
                    }
                }
            });
    };
    openPayment = () => {
        this.setState({ payment_sheet: true });
    };
    closePayment = () => {
        this.setState({ payment_sheet: false });
    };

    // delivery type
    openDeliveyType = () => {
        this.setState({ delivery_sheet: true, delivery_type: true });
    };
    closeDeliveryType = () => {
        this.setState({
            delivery_sheet: false,
            scheduledDate: "",
            scheduledTime: "",
            delivery_type: false,
        });
    };

    setOnline = () => {
        this.setState({
            payment_type: "ONLINE",
            paymentSelected: true,
            payment_sheet: false,
        });
    };

    setCod = () => {
        this.setState({
            payment_type: "COD",
            paymentSelected: true,
            payment_sheet: false,
        });
    };

    redeemWalletAmt = (e, amt) => {
        e.preventDefault();

        if (amt > 0) {
            this.setState({
                canPayPartialWithWallet: true,
                canPayFullWithWallet: false,
            });
        }
        if (amt >= parseFloat(this.getTotalAfterCalculation())) {
            this.setState({
                canPayFullWithWallet: true,
                canPayPartialWithWallet: false,
            });
            this.setState({
                payment_type: "WALLET",
                paymentSelected: true,
                payment_sheet: false,
            });
        }

        this.setState({ walletAmount: amt });
    };

    removeWalletAmt = () => {
        this.setState({
            payment_type: "",
            paymentSelected: false,
            payment_sheet: false,
            canPayFullWithWallet: false,
            canPayPartialWithWallet: false,
            walletAmount: 0,
        });
    };

    addTip = (e) => {
        this.setState({ tip: e });
        localStorage.setItem("tip", e);
    };

    calculatetip = () => {
        if (this.state.tip) {
            if (this.state.tip === "other") {
                if (this.state.custom_tip) {
                    return this.state.custom_tip;
                } else {
                    return 0;
                }
            } else {
                return this.state.tip;
            }
        } else {
            return 0;
        }
    };

    handleDateTime = (e, type) => {
        // ////// console.log(e.target.value);
        if (type === "DATE") {
            this.setState({
                scheduledDate: e.target.value,
            });
        }
        if (type === "TIME") {
            this.setState({
                scheduledTime: e.target.value,
            });
        }
    };

    render() {
        const {
            total,
            cartTotal,
            deliveryCharges,
            discount,
            cartProducts,
            charges,
            user,
            store_info,
            max_distance,
        } = this.props;
        const {
            tip,
            nearstores,
            AddressNotMatch,
            payment_type,
            paymentSelected,
            payment_sheet,
            canPayFullWithWallet,
            canPayPartialWithWallet,
            walletAmount,
            payment_methods,
        } = this.state;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : this.state.loadingTwo ? (
                    <React.Fragment>
                        <LoadingTwo />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="d-flex flex-column">
                            <div className="mobile-ntfcn-head py-4">
                                <div className="pt-3 px-1">
                                    <MobileBackButton
                                        history={this.props.history}
                                    />
                                </div>
                                <div className="pt-5 ps-4">
                                    <span className="">Confirm Order</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column align-items-center px-4">
                                <div
                                    className="mobile-card p-2 pb-3 d-flex flex-column w-100"
                                    style={{ border: "1px solid #fea24b" }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            paddingTop: "0.6em",
                                        }}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <p
                                            style={{
                                                color: "#7b7b7b",
                                                fontSize: "14px",
                                                fontWeight: "650",
                                            }}
                                        >
                                            Deliver To
                                        </p>
                                        <Link to="/my-address">
                                            <div className="edit-confirm">
                                                Edit
                                            </div>
                                        </Link>
                                    </div>

                                    <div
                                        className="mobile-location d-flex flex-row align-items-center"
                                        style={{ paddingLeft: "10px" }}
                                    >
                                        <img
                                            src={editlocation}
                                            className="loc"
                                            alt="EditLocation"
                                        />
                                        <div
                                            style={{ paddingLeft: "10px" }}
                                            className="d-flex flex-column"
                                        >
                                            <div
                                                style={{
                                                    paddingBottom: "0px",
                                                    color: "#000",
                                                }}
                                                className="fs-10"
                                            >
                                                {this.state.address?.house
                                                    ? this.state.address
                                                          ?.house + ", "
                                                    : ""}
                                                {this.state.address?.address}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="mobile-card5 d-flex flex-column mt-2 w-100"
                                    style={{ border: "1px solid #fea24b" }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: "20px",
                                            paddingTop: "1em",
                                        }}
                                        className="d-flex justify-content-between"
                                    >
                                        <p
                                            style={{
                                                color: "#7b7b7b",
                                                fontSize: "14px",
                                                fontWeight: "650",
                                            }}
                                        >
                                            Payment Method
                                        </p>
                                        {this.state.paymentSelected ? (
                                            <div
                                                className="edit-confirm"
                                                onClick={() =>
                                                    this.openPayment()
                                                }
                                            >
                                                Edit
                                            </div>
                                        ) : null}
                                    </div>
                                    {this.state.paymentSelected == true ? (
                                        <React.Fragment>
                                            {this.state.payment_type ==
                                            "WALLET" ? (
                                                <div className="mobile-location d-flex flex-row align-items-center">
                                                    <img
                                                        src={walletImg}
                                                        className="loc"
                                                        alt="EditLocation"
                                                    />
                                                    <span
                                                        style={{
                                                            paddingLeft: "10px",
                                                        }}
                                                        className="d-flex flex-column"
                                                    >
                                                        <h6
                                                            style={{
                                                                marginBottom:
                                                                    "0",
                                                            }}
                                                        >
                                                            Wallet Payment
                                                        </h6>
                                                    </span>
                                                </div>
                                            ) : this.state.payment_type ==
                                              "ONLINE" ? (
                                                <div className="mobile-location text-center align-items-center">
                                                    <img
                                                        src={upi}
                                                        className="loc"
                                                        alt="EditLocation"
                                                    />
                                                    {/* <span  style={{ paddingLeft: "10px" }} className="d-flex flex-column"  >
                                                         <h6 style={{ paddingBottom: "0px" }}>    UPI PAYMENT  </h6>
                                                       </span> */}
                                                </div>
                                            ) : (
                                                <div className="mobile-location d-flex flex-row justify-content-center align-items-center">
                                                    <img
                                                        src={cod}
                                                        className="loc"
                                                        alt="EditLocation"
                                                    />
                                                    <span
                                                        style={{
                                                            paddingLeft: "10px",
                                                            paddingBottom:
                                                                "0px",
                                                            fontWeight: "650",
                                                            fontSize: "1.2em",
                                                        }}
                                                    >
                                                        Cash on Delivery
                                                    </span>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <div
                                            className="mobile-location d-flex flex-row align-items-center justify-content-between px-4"
                                            onClick={() => this.openPayment()}
                                        >
                                            <div
                                                style={{
                                                    fontSize: "1.3em",
                                                    fontWeight: "750",
                                                }}
                                            >
                                                Choose a Payment Method
                                            </div>
                                            <div>
                                                <IoIosArrowDropright
                                                    size={"1.5em"}
                                                    color={"#3f3f3f"}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* instant and sheduled */}
                                {nearstores &&
                                    nearstores.length > 0 &&
                                    nearstores.find(
                                        (cp) =>
                                            cp.id === cartProducts[0]?.store?.id
                                    ) && (
                                        <>
                                            {nearstores[0]?.delivery_type ==
                                                3 && (
                                                <div
                                                    className="mobile-card5 d-flex flex-column mt-2 w-100"
                                                    style={{
                                                        border: "1px solid #fea24b",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingLeft: "20px",
                                                            paddingTop: "1em",
                                                        }}
                                                        className="d-flex justify-content-between"
                                                    >
                                                        <p
                                                            style={{
                                                                color: "#7b7b7b",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "650",
                                                            }}
                                                        >
                                                            Delivery Type
                                                        </p>
                                                    </div>

                                                    <div className="mobile-location d-flex flex-row align-items-center justify-content-between px-4">
                                                        <div
                                                            className={
                                                                this.state
                                                                    .delivery_type
                                                                    ? "delivery-type"
                                                                    : "active-type "
                                                            }
                                                        >
                                                            Instant
                                                        </div>
                                                        <div
                                                            className={
                                                                this.state
                                                                    .delivery_type
                                                                    ? "active-type"
                                                                    : "delivery-type"
                                                            }
                                                            onClick={() =>
                                                                this.openDeliveyType()
                                                            }
                                                        >
                                                            Scheduled
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {nearstores[0]?.delivery_type ==
                                                2 && (
                                                <div
                                                    className="mobile-card5 d-flex flex-column mt-2 w-100"
                                                    style={{
                                                        border: "1px solid #fea24b",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingLeft: "20px",
                                                            paddingTop: "1em",
                                                        }}
                                                        className="d-flex justify-content-between"
                                                    >
                                                        <p
                                                            style={{
                                                                color: "#7b7b7b",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "650",
                                                            }}
                                                        >
                                                            Delivery Type
                                                        </p>
                                                    </div>

                                                    <div className="mobile-location d-flex flex-row align-items-center justify-content-between px-4">
                                                        <div
                                                            className={
                                                                this.state
                                                                    .delivery_type
                                                                    ? "active-type"
                                                                    : "delivery-type"
                                                            }
                                                            onClick={() =>
                                                                this.openDeliveyType()
                                                            }
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {this.state
                                                                .delivery_type
                                                                ? "Scheduled"
                                                                : "Click here to schedule your order"}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}

                                {user.data.wallet_balance > 0 ? (
                                    <div
                                        className="mobile-card2  d-flex flex-column mt-2 w-100"
                                        style={{ border: "1px solid #fea24b" }}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: "20px",
                                                paddingTop: "1em",
                                            }}
                                            className="d-flex justify-content-between"
                                        >
                                            <p
                                                style={{
                                                    color: "#7b7b7b",
                                                    fontSize: "14px",
                                                    fontWeight: "650",
                                                }}
                                            >
                                                Wallet Redeem
                                            </p>
                                        </div>
                                        <div className="pb-3 w-100  d-flex flex-row justify-content-between align-items-center">
                                            <div className="px-3">
                                                <img
                                                    src={walletImg}
                                                    className="mscard1"
                                                    alt="mscard1"
                                                />
                                                {this.state
                                                    .canPayFullWithWallet ? (
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                            paddingLeft: "10px",
                                                        }}
                                                    >
                                                        {Number(
                                                            (
                                                                user.data
                                                                    .wallet_balance -
                                                                this.getTotalAfterCalculation()
                                                            ).toFixed(2)
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            fontWeight: "800",
                                                            paddingLeft: "10px",
                                                        }}
                                                    >
                                                        {Number(
                                                            (
                                                                user.data
                                                                    .wallet_balance -
                                                                this.state
                                                                    .walletAmount
                                                            ).toFixed(2)
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                            <div className="d-flex align-items-center px-3">
                                                <div
                                                    className="redeem-btn me-3 p-1"
                                                    onClick={(e) =>
                                                        this.redeemWalletAmt(
                                                            e,
                                                            user.data
                                                                .wallet_balance
                                                        )
                                                    }
                                                >
                                                    Redeem
                                                </div>
                                                {payment_type ||
                                                paymentSelected ||
                                                payment_sheet ||
                                                canPayFullWithWallet ||
                                                canPayPartialWithWallet ||
                                                walletAmount > 0 ? (
                                                    <span
                                                        style={{ color: "red" }}
                                                        onClick={
                                                            this.removeWalletAmt
                                                        }
                                                    >
                                                        remove
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <Tip
                                addTip={(e) => this.addTip(e)}
                                tip={this.state.tip}
                            />
                            <CancelOrder />
                            {nearstores &&
                            nearstores.length > 0 &&
                            nearstores.find(
                                (cp) => cp.id === cartProducts[0]?.store?.id
                            ) ? (
                                <div
                                    className="position-fixed bottom-0 w-100"
                                    style={{
                                        bottom: "0",
                                        borderRadius: "10px 10px 0px 0px",
                                        boxShadow: "0px 0px 10px 0px #dedede",
                                    }}
                                >
                                    <div className="mobile-cart-bill-card px-3 py-3">
                                        <div className="d-flex flex-row justify-content-between px-2">
                                            <div style={{ color: "white" }}>
                                                Sub Total
                                            </div>
                                            <div style={{ color: "white" }}>
                                                Rs{" "}
                                                {/* {formatPrice(cartTotal.totalPrice + cartTotal.totalDiscount)} */}
                                                {formatPrice(
                                                    cartTotal.totalPrice
                                                )}
                                            </div>
                                        </div>
                                        {/* {this.state.delivery_charges === 0 ? (
                                          ""
                                              ) :  ( */}
                                        {/* <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Delivery Charge
                                                </div>
                                                <div style={{ color: "white" }}>  Rs{" "}
                                                    {this.state.delivery_charges ? this.state.delivery_charges : 0.00}
                                                </div>
                                            </div> */}
                                        {/* )} */}

                                        <div className="d-flex flex-row justify-content-between px-2">
                                            {this.state.delivery_charges > 0 ? (
                                                <>
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Delivery Charge (
                                                        {parseFloat(
                                                            localStorage.getItem(
                                                                "max_distance"
                                                            )
                                                        ).toFixed(2)}
                                                        km)
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        {" "}
                                                        Rs{" "}
                                                        {this.state
                                                            .delivery_charges
                                                            ? this.state
                                                                  .delivery_charges
                                                            : 0.0}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        style={{
                                                            color: "green",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Delivery Charge (
                                                        {parseFloat(
                                                            localStorage.getItem(
                                                                "max_distance"
                                                            )
                                                        ).toFixed(2)}
                                                        km)
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "green",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        FREE DELIVERY
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        {tip ? (
                                            <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Tip amount
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    Rs {formatPrice(tip)}
                                                </div>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        {charges && this.StoresTax() > 0 && (
                                            <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Tax & Changes
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    Rs {this.StoresTax()}
                                                </div>
                                            </div>
                                        )}

                                        {store_info.handling_charges &&
                                            store_info.handling_charges > 0 && (
                                                <div className="d-flex flex-row justify-content-between px-2">
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Handling Charges
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Rs{" "}
                                                        {parseFloat(
                                                            store_info.handling_charges
                                                        ).toFixed(2)}
                                                    </div>
                                                </div>
                                            )}
                                        {store_info &&
                                            store_info.city &&
                                            store_info.city.surge_fee &&
                                            store_info &&
                                            store_info.city &&
                                            store_info.city.surge_fee > 0 && (
                                                <div className="d-flex flex-row justify-content-between px-2">
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Surge Amount
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    >
                                                        Rs{" "}
                                                        {store_info &&
                                                        store_info.city &&
                                                        store_info.city
                                                            .surge_fee
                                                            ? formatPrice(
                                                                  store_info
                                                                      .city
                                                                      .surge_fee
                                                              )
                                                            : 0.0}
                                                    </div>
                                                </div>
                                            )}
                                        {/* <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Discount
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    - Rs {formatPrice(cartTotal.totalDiscount)}
                                                </div>
                                            </div> */}
                                        {this.props.coupon &&
                                        this.props.coupon.code ? (
                                            <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Coupon
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    - Rs{" "}
                                                    {formatPrice(
                                                        this.props.coupon
                                                            .finalDiscount
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        {this.state.canPayFullWithWallet ==
                                        true ? (
                                            <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Wallet Discount
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    - Rs{" "}
                                                    {this.getTotalAfterCalculation()}
                                                </div>
                                            </div>
                                        ) : this.state
                                              .canPayPartialWithWallet ==
                                          true ? (
                                            <div className="d-flex flex-row justify-content-between px-2">
                                                <div style={{ color: "white" }}>
                                                    Wallet Discount
                                                </div>
                                                <div style={{ color: "white" }}>
                                                    - Rs{" "}
                                                    {this.state.walletAmount}
                                                </div>
                                            </div>
                                        ) : (
                                            "  "
                                        )}
                                        {/* <div className="d-flex flex justify-content-between py-3 px-2">
                                                <div style={{ fontSize: "18px", fontWeight: "700", }} >
                                                    Total
                                                </div>
                                                {this.state.canPayFullWithWallet == true ? (
                                                    <div style={{ fontSize: "18px", fontWeight: "700", }}   >
                                                        Rs: 0
                                                    </div>
                                                ) : (
                                                    <div style={{ fontSize: "18px", fontWeight: "700", }}  >
                                                        Rs: {this.getTotalAfterCalculation() - this.state.walletAmount}
                                                    </div>
                                                )}
                                            </div> */}
                                        <hr
                                            className="my-2"
                                            style={{
                                                border: "1px dashed #fff",
                                            }}
                                        />
                                        <div className="d-flex justify-content-between align-items-center px-2">
                                            <div className="d-flex flex-column justify-content-between px-2 pe-3 fw-bolder">
                                                <div
                                                    style={{
                                                        color: "white",
                                                        fontSize: "1.2em",
                                                    }}
                                                >
                                                    Total
                                                </div>
                                                {this.state
                                                    .canPayFullWithWallet ==
                                                true ? (
                                                    <div
                                                        style={{
                                                            color: "white",
                                                            fontSize: "1.2em",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Rs 0.00
                                                    </div>
                                                ) : this.state
                                                      .canPayPartialWithWallet ==
                                                  true ? (
                                                    <div
                                                        style={{
                                                            color: "white",
                                                            fontSize: "1.2em",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Rs{" "}
                                                        {Number(
                                                            this.getTotalAfterCalculation() -
                                                                this.state
                                                                    .walletAmount
                                                        ).toFixed(2)}
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            color: "white",
                                                            fontSize: "1.2em",
                                                            whiteSpace:
                                                                "nowrap",
                                                        }}
                                                    >
                                                        Rs:{" "}
                                                        {this.getTotalAfterCalculation()}
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                {nearstores[0]?.delivery_type ==
                                                2 ? (
                                                    <>
                                                        {this.state
                                                            .delivery_type ? (
                                                            <button
                                                                style={{
                                                                    color: "#FEA24B",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "700",
                                                                }}
                                                                className="mobile-cart-my-ordr-btn p-3 px-5"
                                                                onClick={
                                                                    this
                                                                        .placeOder
                                                                }
                                                            >
                                                                Place My Order
                                                            </button>
                                                        ) : (
                                                            <button
                                                                onClick={() =>
                                                                    this.openDeliveyType()
                                                                }
                                                                style={{
                                                                    color: "#FEA24B",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "700",
                                                                }}
                                                                className="mobile-cart-my-ordr-btn p-3 px-5"
                                                            >
                                                                Click to shedule
                                                                your order
                                                            </button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <button
                                                        style={{
                                                            color: "#FEA24B",
                                                            fontSize: "14px",
                                                            fontWeight: "700",
                                                        }}
                                                        className="mobile-cart-my-ordr-btn p-3 px-5"
                                                        onClick={this.placeOder}
                                                    >
                                                        Place My Order
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Shake Shake duration={250} right>
                                    <div className="position-fixed bottom-0 w-100 py-2 px-2">
                                        <Link to="/my-address">
                                            <div
                                                className="text-center w-100 py-3"
                                                style={{
                                                    background: "#fdf5eb",
                                                    color: "red",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                We are not currently available
                                                at this location. <br />
                                                Please try changing your
                                                location
                                            </div>
                                        </Link>
                                    </div>
                                </Shake>
                            )}
                        </div>
                    <BottomSheet open={this.state.payment_sheet}
                                onDismiss={() => this.closePayment()}  >
                                <div className="d-flex flex-column justify-content-center px-3 pb-3">
                                    <div className="pt-3">
                                        <p style={{ color: "#7b7b7b", fontSize: "1.2em", fontWeight: "650" }} >
                                            Choose Payment Method
                                        </p>
                                    </div>
                                    <div className="mobile-card2 py-3 w-100 mt-3 d-flex flex-row justify-content-center align-items-center"
                                        onClick={() => this.setCod()} style={{ border: '1px solid #fea24b' }}>
                                        <img src={cod} className="cod" alt="cod" />
                                        <span style={{ fontWeight: "800" }}>
                                            Cash on delivery
                                        </span>
                                    </div>

                                    <div className="mobile-card2 py-3 w-100  d-flex flex-column justify-content-center align-items-center mt-2"
                                        onClick={() => this.setOnline()} style={{ border: '1px solid #fea24b' }}>
                                        <img src={upi} className="upi" alt="upi" />
                                    </div>
                                </div>
                            </BottomSheet>
                             
                        {console.log("payment len ", payment_methods.length)}

                       
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    maxDistanceStore: state.cart.max_distance,
    charges: state.cart.charges,
    slabs: state.cart.slabs,
    cartTotal: state.cart.total,
    total: state.cart.total?.totalPrice,
    coupon: state.mobileCoupon.appliedCoupon,
    cartProducts: state.cart.products,
    deliveryCharges: state.cart.delivery_charges,
    current_slab: state.cart.current_slab,
    store_info: state.cart.store_info,
    prescriptionImage: state.cart.prescriptionImage,
    maxDistanceStore: state.cart.max_distance,
});

export default connect(mapStateToProps, {
    getNearStores,
    getDeliverySlab,
    updateSlabDetails,
    placeOrder,
    clearCart,
    updateUserReduxInfo,
    clearPrescriptionImage,
    calcualteStoreMaxDistance,
})(ConfirmOrder);

import {
    GET_SETTINGS,
    GET_ALL_STORE_CATEGORIES,
    GET_SLIDERS,
    GET_SINGLE_SLIDER,
    GET_COUPONS,
    GET_SINGLE_COUPON,
    GET_ALL_BANNER_FOOD,
    GET_TOP_FOOD,
    GET_TOP_DEALS,
    GET_ALL_RESTURANTS,
    GET_ALL_PAGINATED_RESTURANTS,
    GET_ALL_RECOMMENTED_STORES,
    GET_ALL_NEAR_STORES,
    GET_ALL_POPULAR_STORES,
    GET_ALL_ITEM_CATEGORY,
    GET_ALL_ITEM_GROUP,
    GET_POPULAR_BRANDS,
    GET_DEALS_TODAY,
} from './actionType';
import {
    GET_SETTINGS_URL,
    GET_ALL_STORE_CATEGORIES_URL,
    GET_SLIDERS_URL,
    GET_SINGLE_SLIDER_URL,
    GET_COUPONS_URL,
    GET_SINGLE_COUPON_URL,
    GET_All_BANNER_URL,
    GET_TOP_FOOD_URL,
    GET_TOP_DEALS_URL,
    GET_All_RESTURANTS_URL,
    GET_All_PAGINATED_RESTURANTS_URL,
    GET_ALL_RECOMMENTED_STORES_URL,
    GET_ALL_NEAR_STORES_URL,
    GET_ALL_POPULAR_STORES_URL,
    GET_ALL_ITEM_CATEGORY_URL,
    GET_ALL_ITEM_GROUP_URL,
    GET_POPULAR_BRANDS_URL,
    GET_DEALS_TODAY_URL,

} from "../../../api";

import Axios from 'axios'

export const getSettings = () => (dispatch) => {
    return Axios.post(GET_SETTINGS_URL)
        .then((response) => {
            if (response?.data?.success) {
                if (response?.data?.data) {
                    response?.data?.data.map((key) => {
                        localStorage.setItem(key.key, key.value);
                    })
                }
            }
        })
}

export const getAllStoreCategories = () => (dispatch) => {
    return Axios.post(GET_ALL_STORE_CATEGORIES_URL)
        .then((response) => {
            let storecategories = response.data;
            return dispatch({
                type: GET_ALL_STORE_CATEGORIES,
                payload: storecategories
            })
        })
}
//slider
export const getAllSliders = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SLIDERS_URL, { latitude, longitude })
        .then((response) => {
            let sliders = response.data;
            return dispatch({
                type: GET_SLIDERS,
                payload: sliders
            })
        })
}

export const getSingleSlider = (id) => (dispatch) => {
    return Axios.post(GET_SINGLE_SLIDER_URL, {
        id
    })
        .then((response) => {
            let slider = response.data;
            return dispatch({
                type: GET_SINGLE_SLIDER,
                payload: slider
            })
        })
}
//coupon
export const getAllCoupons = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_COUPONS_URL, { latitude, longitude })
        .then((response) => {
            let coupons = response.data;
            return dispatch({
                type: GET_COUPONS,
                payload: coupons
            })
        })
}

export const getSingleCoupon = (id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_SINGLE_COUPON_URL, {
        id,
        latitude,   // Pass latitude here
        longitude   // Pass longitude here
    })
        .then((response) => {
            let coupon = response.data;
            return dispatch({
                type: GET_SINGLE_COUPON,
                payload: coupon
            });
        })
        .catch((error) => {
            console.error("Error fetching single coupon:", error);
            // Optionally dispatch an error action or handle it accordingly
        });
}


//banner
export const getAllBannerForFood = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_All_BANNER_URL, { latitude, longitude })
        .then((response) => {
            let banners = response.data;
            return dispatch({
                type: GET_ALL_BANNER_FOOD,
                payload: banners
            })
        })
}


//top food
export const getTopFood = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_TOP_FOOD_URL, { latitude, longitude })
        .then((response) => {
            let topFoods = response.data;
            return dispatch({
                type: GET_TOP_FOOD,
                payload: topFoods
            })
        })
}

// TOP DEALS
export const getTopDeals = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_TOP_DEALS_URL, { latitude, longitude })
        .then((response) => {
            let topFoods = response.data;
            return dispatch({
                type: GET_TOP_DEALS,
                payload: topFoods
            })
        })
}

export const getAllResturants = (latitude, longitude, page , pageSize , filters = {}) => (dispatch,getState) => {
    const state = getState();
    const existingFilters = state.home.allStores?.filters || {};

    const payload = {
        latitude,
        longitude,
        page: page || existingFilters.page || 1, // Default to 1 if not provided
        pageSize: pageSize || existingFilters.pageSize || 10, // Default to 10 if not provided
        ...existingFilters, // Include existing filters
        ...filters, // Override with new filters
    };
    return Axios.post(GET_All_RESTURANTS_URL, payload)
        .then((response) => {
            let allStores = response.data;
            return dispatch({
                type: GET_ALL_RESTURANTS,
                payload: {
                    ...allStores,
                    filters: { ...payload },
                },
            });
        })
        .catch((error) => {
            console.error("Error fetching restaurants:", error);
        });
};


export const getAllPaginateResturants = (latitude, longitude, page) => (dispatch) => {
    return Axios.post( GET_All_PAGINATED_RESTURANTS_URL, { latitude, longitude, page })
        .then((response) => {
            let paginatedStores = response.data;
            return dispatch({
                type: GET_ALL_PAGINATED_RESTURANTS,
                payload: paginatedStores
            })
        })
}


export const getAllRecommentedStores = (latitude, longitude, page) => (dispatch) => {
    return Axios.post(GET_ALL_RECOMMENTED_STORES_URL, {
      latitude,
      longitude,
      page,
    })
      .then((response) => {
        const recommentedStores = response.data;
        return dispatch({
          type: GET_ALL_RECOMMENTED_STORES,
          payload: recommentedStores,
        });
      })
      .catch((error) => {
        console.error("Error fetching recommended stores:", error);
        throw error;
      });
  };

export const getAllNearStores = (latitude, longitude, page) => (dispatch) => {
    return Axios.post(GET_ALL_NEAR_STORES_URL, {
         latitude,
          longitude,
          page
        })
        .then((response) => {
            let nearstores = response.data;
            return dispatch({
                type: GET_ALL_NEAR_STORES,
                payload: nearstores
            })
        })
        .catch((error) => {
        console.error("Error fetching near stores:", error);
        throw error;
      });
}

export const getAllPopularStores = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_POPULAR_STORES_URL, {
        latitude, longitude
    })
        .then((response) => {
            let popStores = response.data;
            return dispatch({
                type: GET_ALL_POPULAR_STORES,
                payload: popStores
            })
        })
}

export const getAllItemCategory = () => (dispatch) => {
    return Axios.post(GET_ALL_ITEM_CATEGORY_URL)
        .then((response) => {
            let popStores = response.data;
            return dispatch({
                type: GET_ALL_ITEM_CATEGORY,
                payload: popStores
            })
        })
}

export const getAllItemGroup = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_ITEM_GROUP_URL, {
        latitude, longitude
    })
        .then((response) => {
            let item_group = response.data;
            return dispatch({
                type: GET_ALL_ITEM_GROUP,
                payload: item_group
            })
        })
}

// popular brands
export const getPopularBrands = (latitude, longitude,store_category_id) => (dispatch) => {
    return Axios.post(GET_POPULAR_BRANDS_URL, { latitude, longitude,store_category_id })
        .then((response) => {
            let topFoods = response.data;
            return dispatch({
                type: GET_POPULAR_BRANDS,
                payload: topFoods
            })
        })
}

export const getDealsToday = (latitude, longitude,store_category_id) => (dispatch) => {
    return Axios.post(GET_DEALS_TODAY_URL, {
        latitude, longitude,store_category_id
    })
        .then((response) => {
            let deals = response.data;
            return dispatch({
                type: GET_DEALS_TODAY,
                payload: deals
            })
        })
}
